import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Comprobante } from 'src/app/interfaces/comprobante';
import { LineaComprobante } from 'src/app/interfaces/linea-comprobante';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

/**
 * Componente que permite mover movimientos entre comprobantes
 */
@Component( {
    selector: 'app-mover-movimientos',
    templateUrl: './mover-movimientos.component.html',
    styleUrls: ['./mover-movimientos.component.less'],
} )
export class MoverMovimientosComponent implements OnInit {
    /**
     * Evento de emisión para comunicarse con los componente 'padre'
     */
    @Output() notify: EventEmitter<{}> = new EventEmitter<{}>();

    /**
     * Linea que se moverá de comprobante
     */
    lineaOrigen: LineaComprobante;
    /**
     * Comprobante que recibe el movimiento
     */
    comprobanteDestino: Comprobante;

    /**
     * Controla si se muestra o no el comprobante
     */
    seMuestraMoverMovimiento = false;

    /**
     * Listado de comprobantes del sistema del año actual
     */
    listadoComprobantes: Comprobante[];

    movimiento: LineaComprobante[] = [];

    /**
     * Constructor de la clase con su injección de dependecias.
     * @param http Servicio Http
     * @param state Servicio State
     * @param confirma Servicio de Confirmación
     * @param auth Servicio de Autenticación
     */
    constructor(
        private http: HttpService,
        private state: StateService,
        private confirma: ConfirmationService,
        private auth: AuthenticationService
    ) {}

    /**
     * OnInit básico
     */
    ngOnInit(): void {}

    /**
     * Confirma el cambio de comprobante para el movimiento
     * una vez cambiado cierra todo.
     */
    btnCambiarMovimientoComprobante() {
        const objCambiar = {
            comprobanteIN: + this.lineaOrigen.idComprobante,
            comprobanteOUT: + this.comprobanteDestino.id,
            idMov: + this.lineaOrigen.id,
        };

        this.confirma.confirm( {
            message: `¿ Cambiar el movimiento por ? ${
                this.lineaOrigen.monto < 0
                    ? this.lineaOrigen.monto * - 1
                    : this.lineaOrigen.monto
            } desde el comprobante ${objCambiar.comprobanteIN} al ${
                objCambiar.comprobanteOUT
            } `,
            acceptLabel: 'Si, Mover',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.http
                    .post(
                        this.http.endpoint.cambiarMovimientoComprobante,
                        objCambiar
                    )
                    .subscribe( ( res ) => {
                        if ( res.status === 200 ) {
                            this.lineaOrigen.idComprobante =
                                objCambiar.comprobanteIN;
                            this.seMuestraMoverMovimiento = false;
                            this.state.agregarMensaje(
                                this.state.mensajestipo.ok,
                                'Cambiar movimiento',
                                res.statusText
                            );
                            this.notify.emit( {
                                status: 'OK',
                                data: this.lineaOrigen,
                            } );
                            this.cerrarMoverMovimiento();
                        }
                    } );
            },
        } );
    }

    /**
     * Cierra el modal de mover comprobante
     */
    cerrarMoverMovimiento() {
        this.seMuestraMoverMovimiento = false;
    }

    /**
     * Obtiene el listado de comprobantes del año actual para que el usuario elija donde mover el movimiento
     * @returns La llamada al endpoint que obtiene los comprobantes
     */
    obtenerListadoComprobantes() {
        const obConsulta = {
            codUsuario: this.auth.user.codUsuario,
            agnoActual: this.state.hoy.getFullYear(),
        };
        return this.http.post(
            this.http.endpoint.obtenerListadoComprobantes,
            obConsulta
        );
    }

    /**
     * Despliega el dialog para poder mover un movimiento y llama al obtener listado comprobantes.
     */
    mostrarMoverMovimiento( linea: LineaComprobante ) {
        console.log( linea );
        this.movimiento.push( linea );
        this.lineaOrigen = linea;
        this.seMuestraMoverMovimiento = true;

        this.obtenerListadoComprobantes().subscribe( ( res ) => {
            if ( res.status === 200 ) {
                this.listadoComprobantes = [];

                res.body.datos.forEach(
                    ( cres: {
                        id: number;
                        codComprobante: string;
                        fechaCreacion: Date;
                    } ) => {
                        const com = {
                            id: cres.id,
                            codComprobante: cres.codComprobante,
                            fechaCreacion: cres.fechaCreacion,
                        } as Comprobante;

                        this.listadoComprobantes.push( com );
                    }
                );
            }
        } );
    }
}
