import { Component, EventEmitter, Output } from '@angular/core';
import { LineaComprobante } from 'src/app/interfaces/linea-comprobante';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

/**
 * Componente que permite eliminar un movimiento
 */
@Component({
    selector: 'app-confirma-eliminar-movimientos',
    templateUrl: './confirma-eliminar-movimientos.component.html',
    styleUrls: ['./confirma-eliminar-movimientos.component.less'],
})
export class ConfirmaEliminarMovimientosComponent {
    /**
     * Listado de movimientos a eliminar
     */
    listaMovimientosEliminados: LineaComprobante[];
    /**
     * Notifica a los componentes que utilizan el modal
     */
    @Output() notify: EventEmitter<[object, object]> = new EventEmitter<[object, object]>();
    /**
     * Constrola si el modal se muestra
     */
    estaVisible = false;
    /**
     * Constructor del componente
     * @param http Servicios Http
     * @param state Servicio de estado
     */
    constructor(public http: HttpService, public state: StateService) {}

    /**
     * Muestra el dialog para confirmar la eliminación de los comprobantes.
     * @param movimientos Lineas de comprobante que se van a eliminar
     */
    mostrarEliminarListadoMovimientos(movimientos: LineaComprobante[]) {
        if (movimientos.length > 0) {
            this.listaMovimientosEliminados = movimientos;
            this.estaVisible = true;
        }
    }

    /**
     * Llama al endpoint para eliminar los movimientos y notifica
     */
    eliminar() {
        this.http
            .post(this.http.endpoint.eliminarPartidaDoble, {
                movimientos: this.listaMovimientosEliminados,
            })
            .subscribe((res) => {
                if (res.status === 200) {
                    this.estaVisible = false;
                    this.notify.emit([res, this.listaMovimientosEliminados]);
                }
            });
    }

    /**
     * Cerrar el dialog
     */
    close() {
        this.estaVisible = false;
    }
}
