<p-card>
    <ng-template pTemplate="header">
        <div class="flex justify-content-center">
            <p-calendar
                [(ngModel)]="rangoFechas"
                selectionMode="range"
                [readonlyInput]="true"
                inputId="rango"
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                rangeSeparator="/"
                [inputStyle]="{ width: '100%' }"
                [showButtonBar]="true"
                firstDayOfWeek="1"
                styleClass="p-calendar-sm mr-2">
            </p-calendar>

            <button
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded bg-primary"
                (click)="obtenerSaldosMovimientosCuentas()"
                pTooltip="Filtrar movimientos"
                tooltipPosition="right"
                tooltipZIndex="100000000"></button>
        </div>

    </ng-template>
    <p-chart
        type="line"
        [data]="saldosDiarios"
        [options]="options"></p-chart>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-center">
            <p-toggleButton
                onLabel="Por cuenta"
                offLabel="Consolidado"
                (onChange)="cambiaGrafico()"
                [(ngModel)]="consolidado"></p-toggleButton>
        </div>
    </ng-template>
</p-card>
