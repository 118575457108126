import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Cuenta } from 'src/app/interfaces/cuenta';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from '../../servicios/http.service';
import { StateService } from '../../servicios/state.service';

/**
 * Componente que permite generar o editar una cuenta o categoría
 */
@Component( {
    selector: 'app-crear-cuentas-modal',
    templateUrl: './crear-cuentas-modal.component.html',
    styleUrls: ['./crear-cuentas-modal.component.less'],
} )
export class CrearCuentasModalComponent implements OnInit {
    /**
     * Notifica los eventos del modal a los componentes que lo utilizan
     */
    @Output() notify: EventEmitter<string> = new EventEmitter<string>();
    /**
     * Formulario para la cuenta
     */
    formCuenta: UntypedFormGroup;
    /**
     * Control para mostrar los tipos de cuenta
     */
    tiposdecuenta: SelectItem[] = [];
    /**
     * Se utiliza para modificar el título a mostrar
     */
    tituloMostrar: string;
    /**
     * Controla si el modal se muestra
     */
    displayModal = false;
    /**
     * Cuenta que se está mostrando o editando
     */
    cuenta: Cuenta;

    /**
     * Constructor del componente
     * @param formBuilder Utilizado para el formulario
     * @param http  Servicios Http
     * @param state Servicio de estado
     * @param auth Servicios de autenticación
     */
    constructor(
        private formBuilder: UntypedFormBuilder,
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService
    ) {
        this.formCuenta = this.formBuilder.group( {
            txtdescripcion: new UntypedFormControl(),
            ddtipocuenta: new UntypedFormControl(),
            esIngreso: new UntypedFormControl(),
            color: new UntypedFormControl(),
        } );
    }

    /**
     * Oninit que inicializa el listado de tipos de cuentas
     */
    ngOnInit(): void {
        this.obtenerTiposCuenta();
    }

    /**
     * Muestra el Popup para crear una cuenta
     */
    mostrarAgregarNuevaCuentaCategoria() {
        this.displayModal = true;
        this.tituloMostrar = 'Agregar';
    }

    /**
     * Edita una cuenta categoría
     * @param cta Cuenta a editar
     */
    mostrarEditarCuentaCategoria( cta: Cuenta ) {

        this.cuenta = cta;
        this.displayModal = true;
        this.tituloMostrar = 'Editar';

        this.formCuenta.controls.txtdescripcion.setValue( cta.nombre );
        this.formCuenta.controls.ddtipocuenta.setValue( cta.codTipoCuenta );
        this.formCuenta.controls.esIngreso.setValue( cta.esIngreso );
        this.formCuenta.controls.color.setValue( cta.color );
    }

    /**
     * Cierra el Popup
     */
    close() {
        this.displayModal = false;
    }

    /**
     * Guarda los datos del formulario para la cuenta nueva
     */
    guardarNuevaCuenta() {
        this.close();

        const datosaGuardar = {
            nombre: this.formCuenta.controls.txtdescripcion.value,
            codTipoCuenta: this.formCuenta.controls.ddtipocuenta.value,
            codUsuario: this.auth.user.codUsuario,
            esIngreso: this.formCuenta.controls.esIngreso.value,
            color: this.formCuenta.controls.color.value,
            fechaCreacion: new Date(
                this.state.hoy.getTime() -
                    this.state.hoy.getTimezoneOffset() * 60000
            ),
        };

        this.http
            .post( this.http.endpoint.cuentasCrear, datosaGuardar )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.ok,
                        ' Correcto ',
                        ' Cuenta creada exitosamente '
                    );
                    this.notify.emit( 'ok' );
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en guardarNuevaCuenta',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Guarda los datos del formulario para la cuenta nueva
     */
    actualizarCuenta() {
        this.close();

        const datosaGuardar = {
            id: this.cuenta.id,
            nombre: this.formCuenta.controls.txtdescripcion.value,
            codTipoCuenta: this.formCuenta.controls.ddtipocuenta.value,
            codUsuario: this.auth.user.codUsuario,
            esIngreso: this.formCuenta.controls.esIngreso.value,
            color: this.formCuenta.controls.color.value,
            fechaCreacion: new Date(
                this.state.hoy.getTime() -
                    this.state.hoy.getTimezoneOffset() * 60000
            ),
        };

        this.http
            .post( this.http.endpoint.cuentasActualizar, datosaGuardar )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.ok,
                        ' Correcto ',
                        ' Cuenta actualizada'
                    );
                    this.notify.emit( 'ok' );
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en actualizarCuenta',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Obtiene los tipos de cuenta paraa el filtro
     */
    obtenerTiposCuenta() {

        this.http.get( this.http.endpoint.cuentastipos ).subscribe( ( res ) => {
            if ( res.status === 200 ) {
                this.tiposdecuenta = res.body.datos;
            } else {
                this.state.agregarMensaje(
                    this.state.mensajestipo.error,
                    ' Error al obtenerTiposCuenta',
                    res.status + ' : ' + res.statusText
                );
            }
        } );
    }
}
