import { Component, OnInit, ViewChild, effect, viewChild } from '@angular/core';
import { MenuItem, SelectItem, TreeNode } from 'primeng/api';
import { HttpService } from '../../servicios/http.service';
import { StateService } from '../../servicios/state.service';
import { AgregarMovimientosComponent } from '../agregar-movimientos/agregar-movimientos.component';
import { LineaComprobante } from 'src/app/interfaces/linea-comprobante';
import { Comprobante } from 'src/app/interfaces/comprobante';
import { ActivatedRoute, Router } from '@angular/router';
import { MoverMovimientosComponent } from '../mover-movimientos/mover-movimientos.component';
import * as FileSaver from 'file-saver';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { ConfirmaEliminarMovimientosComponent } from 'src/app/dialog/confirma-eliminar-movimientos/confirma-eliminar-movimientos.component';
import { Cuenta } from 'src/app/interfaces/cuenta';
import { AppConfigService } from 'src/app/servicios/app-config.service';
import { TreeTable } from 'primeng/treetable';

/**
 * Componente que muestra un movimiento con todos sus atributos
 */
@Component( {
    selector: 'app-ver-movimientos',
    templateUrl: './ver-movimientos.component.html',
    styleUrls: ['./ver-movimientos.component.less'],
} )
export class VerMovimientosComponent implements OnInit {

    @ViewChild( TreeTable )
        tt: TreeTable;

    /**
     * Componente modal que permite agrega un nuevo movimiento o transferencia.
     */
    @ViewChild( AgregarMovimientosComponent )
        agregarMovComponent: AgregarMovimientosComponent;

    /**
     * Componente que permite mover un movimiento
     */
    @ViewChild( MoverMovimientosComponent )
        moverComprobante: MoverMovimientosComponent;

    /**
     * Componente modal que controla las confirmaciones de eliminar un movimiento
     */
    @ViewChild( ConfirmaEliminarMovimientosComponent )
        eliminarComponent: ConfirmaEliminarMovimientosComponent;
    /**
     * Recibe los datos en bruto desde el backend
     */
    datosBack: any[];
    /**
     * Columnas de la tabla que se pueden ordenar
     */
    cols: any[];
    /**
     * Opciones de agrupación de datos de la tabla
     */
    agrupacionesDisponibles: SelectItem[];
    /**
     * Opción que determina el tipo de agrupación
     */
    agruparPor: string;
    /**
     * Estructura de árbol para la tabla Treenode
     */
    arbolDeMovimientos: TreeNode[] = [];
    /**
     * Rango de fecha para los filtros del calendario
     */
    rangoFechas: Date[];
    /**
     * Menú de fila para cada movimiento
     */
    itemsMenuFila: MenuItem[] = [];
    /**
     * Almacena los movimientos que se seleccionan de la tabla
     */
    movimientoSeleccionado: TreeNode;
    /**
     * Movimientos marcados de la tabla para operaciones por lotes
     */
    movimientosLotes: TreeNode[] = [];
    /**
     * Monto de la suma total de los movimientos marcados en lote
     */
    sumaLote: number;
    /**
     * Lista de Cuentas del sistema
     */
    listadoCuentas: Cuenta[];
    /**
     * Lista de categorías del sistema
     */
    listadoCategorias: Cuenta[];

    /**
     * Tamaño de la tabla para la visualización
     */
    sizeTable: string;
    sizeTableTree: string;

    /**
     * Constructor del componente
     * @param http Servicios Http
     * @param state Servicio de estado
     * @param auth Servicios de autenticación
     * @param route Servicio de gestión de redirección
     * @param router Servicio de gestión de rutas
     */
    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        public appConfig: AppConfigService
    ) {
        this.sizeTable = this.appConfig.sizeClass();
        this.sizeTableTree = this.appConfig.sizeClassTree();

        this.state.configurarModulo(
            'Movimientos',
            'Listado de movimientos entre fechas',
            true
        );
        this.seteaFechasIniciales();

        // Obtener las cuentas y categorias subscribiendonos mediante el servicio
        this.state.listadoCuentasDisponibles$.subscribe( ( ctas: Cuenta[] ) => {
            this.listadoCuentas = ctas.filter(
                ( cta ) => cta.codTipoCuenta === 'CTA'
            );
            this.listadoCategorias = ctas.filter(
                ( cta ) => cta.codTipoCuenta === 'CAT'
            );

            // Ordenamiento
            this.listadoCategorias.sort( ( a, b ) => {
                const n = a.nombre
                    .toLocaleLowerCase()
                    .localeCompare( b.nombre.toLocaleLowerCase() );
                return n === 0 && a !== b
                    ? b.nombre.localeCompare( a.nombre )
                    : n;
            } );

            this.listadoCuentas.sort( ( a, b ) => {
                const n = a.nombre
                    .toLocaleLowerCase()
                    .localeCompare( b.nombre.toLocaleLowerCase() );
                return n === 0 && a !== b
                    ? b.nombre.localeCompare( a.nombre )
                    : n;
            } );
        } );
    }

    /**
     * El efecto se dispara cada vez que una señal cambia
    */
    public sizetableChangedEffect = effect( () => {

        this.sizeTable = this.appConfig.sizeClass();
        this.sizeTableTree = this.appConfig.sizeClassTree();
    } );

    /**
     * Inicializa el componente setea las agrupaciones disponible para el listado de movimientos
     */
    ngOnInit(): void {
        // la vista de movimientos se puede agrupar por los siguientes campos
        this.agrupacionesDisponibles = [
            { label: 'No Agrupar', value: 'id' },
            { label: 'Fecha', value: 'fechaCreacionCorta' },
            { label: 'Comprobante', value: 'idComprobante' },
            { label: 'Cuenta', value: 'cuenta' },
        ];
        this.agruparPor = this.agrupacionesDisponibles[0].value;
        this.agrupar();
    }

    /**
     *  Crea un MenuItem para las opciones de cada línea
     */
    crearMenuContextual() {
        // se agrega el menu desplegable
        this.itemsMenuFila = [
            {
                label: 'Ver movimiento',
                icon: 'pi pi-pencil',
                command: () => {
                    this.btnVerMovimiento( this.movimientoSeleccionado );
                },
            },
            {
                label: 'Ver comprobante',
                icon: 'pi pi-search',
                command: () => {
                    this.btnVerComprobante();
                },
            },
            {
                label: 'Eliminar movimiento',
                icon: 'pi pi-trash',
                command: () => {
                    this.eliminarMovimientoSeleccionado();
                },
            },
            {
                label: 'Mover a ...',
                icon: 'pi pi-clone',
                command: () => {
                    this.mostrarMoverMovimiento();
                },
            },
        ];
    }

    /**
     * Con este método controlamos que solo esté habilitado el menu conextual en los nodos con id
     * @param contextmenu Menu fila
     */
    onContextMenuSelect( contextmenu ) {
        if ( ! this.movimientoSeleccionado?.data?.id ) {
            contextmenu.hide();
        }
    }
    /**
     * Establece las fechas del control de rango de fecha inicial
     * Inicializa el rango de fechas desde el primer día del mes pasado hasta hoy
     */
    seteaFechasIniciales() {
        this.rangoFechas = [];
        const actual = new Date();

        this.rangoFechas.push( this.state.inicioAño );
        this.rangoFechas.push( this.state.finAño );
    }

    /**
     * Despliega modal que permite crear o ver un comprobante
     */
    btnVerComprobante() {
        const comp = this.movimientoSeleccionado
            ? this.movimientoSeleccionado.data.idComprobante
            : null;
        this.router.navigate( ['/comprobante', comp], {
            relativeTo: this.route,
        } );
    }

    /**
     * Recorre un listado de treeNodes y obtiene sus data que son LineaComprobante en un único arreglo de LineaComprobante
     * @param listaTree Lista de Treenode a recorrer
     * @returns un único arreglo de LineaComprobante
     */
    obtieneLineasComprobanteDeUnTreeNode(
        listaTree: TreeNode[]
    ): LineaComprobante[] {
        let lineas: LineaComprobante[] = [];

        listaTree.forEach( ( tn ) => {
            lineas = lineas.concat( tn.data );
        } );

        return lineas;
    }

    /**
     * Muestra una linea de movimiento para su edición
     */
    btnVerMovimiento( mov ) {
        this.agregarMovComponent.mostrarPartidaDoble( mov.data );
    }

    /**
     * Muestra ventana de crear movimiento sin comprobante
     */
    btnAgregarMovimiento() {
        this.agregarMovComponent.mostrarAgregarMovimiento();
    }

    /**
     * Muestra ventana para agregar transferencia
     */
    btnAgregarTransferencia() {
        this.agregarMovComponent.mostrarAgregarTransferencia();
    }

    /**
     * Elimina el movimiento del nodo que está seleccionado por menú contextual
     */
    eliminarMovimientoSeleccionado() {
        const movimientos: TreeNode[] = [];
        movimientos.push( this.movimientoSeleccionado );

        this.eliminarComponent.mostrarEliminarListadoMovimientos(
            this.obtieneLineasComprobanteDeUnTreeNode( movimientos )
        );
    }

    /**
     * Procesa la eliminación de las lineas que se han seleccionado por lotes
     */
    eliminarMovimientosPorLotes() {
        // Solo son movimientos si tienen data id (sino son nodos que agrupan)
        this.eliminarComponent.mostrarEliminarListadoMovimientos(
            this.obtieneLineasComprobanteDeUnTreeNode(
                this.movimientosLotes.filter( ( m ) => m.data.id )
            )
        );
    }

    /**
     * Método que recibe la respuesta del dialog de eliminar movimientos
     * @param emitido La respuesta y los movimientos
     */
    respuestaEliminarMovimientos( emitido ) {
        // this.totalizarSeleccionados();
        const res = emitido[0];
        const movimientos: LineaComprobante[] = emitido[1];

        this.state.agregarMensaje(
            this.state.mensajestipo.ok,
            'Eliminar Movimiento',
            res.statusText
        );

        //  Una vez eliminados de la base de datos,
        //  se eliminan del arbol de movimientos y de movimientos seleccionados si existe
        //  Se recorren los hijos además por si el listado es un arbol con nodos agrupados
        movimientos.forEach( ( mov ) => {
            this.arbolDeMovimientos.forEach( ( nodo ) => {
                // El unico detalle que falta es que si se eliminan todos los hijos
                // el nodo padre sigue en el arbol hasta que se refresque
                if ( nodo.children.length > 0 ) {
                    nodo.children = nodo.children.filter(
                        ( n ) => n.data.id !== mov.id
                    );
                    if ( nodo.children.length === 0 ) {
                        this.arbolDeMovimientos =
                            this.arbolDeMovimientos.filter( ( m ) => m !== nodo );
                        this.movimientosLotes = this.movimientosLotes.filter(
                            ( m ) => m !== nodo
                        );
                    }
                }
            } );

            this.arbolDeMovimientos = this.arbolDeMovimientos.filter(
                ( m ) => m.data.id !== mov.id
            );
            this.movimientosLotes = this.movimientosLotes.filter(
                ( m ) => m.data.id !== mov.id
            );
        } );

        this.totalizarArbolDeMovimientos();
        this.totalizarSeleccionados();
    }

    /**
     * Recorrer el arbol de movimientos y sumar los montos de los hijos, si tiene, en el monto del padre
     */
    totalizarArbolDeMovimientos() {
        this.arbolDeMovimientos.forEach( ( a ) => {
            let suma = 0;

            if ( a.children.length > 0 ) {
                a.children.forEach( ( c ) => {
                    suma += + c.data.monto;
                } );
                a.data.monto = suma;
            }
        } );
    }

    /**
     * Ordena las columnas para agrupar
     * @returns las columnas ordenadas
     */
    ordenaColumnasSegunAgrupacion() {
        const cols = [];

        const baseCol = [
            {
                order: 1,
                field: 'fechaCreacion',
                header: 'Fecha',
                width: '15%',
                agrupa: false,
                estilo: 'text-left',
                pipe: 'formatoFecha',
                display: '',
                tooltip: false,
                fieldBusqueda: 'fechaCreacion',
            },
            {
                order: 2,
                field: 'idComprobante',
                header: 'Comp.',
                width: '15%',
                agrupa: false,
                estilo: 'text-left',
                pipe: null,
                display: '',
                tooltip: false,
                fieldBusqueda:  'codComprobante'

            },
            {
                order: 3,
                field: 'cuenta',
                subfield: 'nombre',
                header: 'Cuenta',
                width: '20%',
                agrupa: false,
                estilo: 'priority-2',
                pipe: null,
                display: '',
                tooltip: false,
                fieldBusqueda: 'cuenta.nombre'
            },
            {
                order: 4,
                field: 'categoria',
                subfield: 'nombre',
                header: 'Categoría',
                width: '15%',
                agrupa: false,
                estilo: 'priority-2',
                pipe: null,
                display: '',
                tooltip: false,
                fieldBusqueda: 'categoria.nombre'
            },
            {
                order: 5,
                field: 'glosa',
                header: 'Glosa',
                width: '17%',
                agrupa: false,
                estilo: 'priority-3',
                pipe: null,
                display: '',
                tooltip: true,
                fieldBusqueda: 'glosa'
            },
            {
                order: 6,
                field: 'monto',
                header: 'Monto',
                width: '18%',
                agrupa: false,
                estilo: 'text-right',
                pipe: 'formatoMoneda',
                display: '',
                tooltip: false,
                fieldBusqueda: 'monto'
            },
            {
                order: 0,
                field: 'codComprobante',
                header: 'Comprobante',
                width: '15%',
                agrupa: false,
                estilo: 'text-left',
                pipe: null,
                display: 'none',
                tooltip: false,
            },
            {
                order: 7,
                field: 'fechaCreacionCorta',
                header: 'Fecha',
                width: '15%',
                agrupa: false,
                estilo: 'text-left',
                pipe: null,
                display: '',
                tooltip: false,
            },
        ];

        // Lógica de ordenamiento
        let ordenamiento: number[] = [1, 2, 0, 3, 4, 5, 6];
        if ( this.agruparPor === 'cuenta' ) {
            ordenamiento = [3, 2, 0, 1, 4, 5, 6];
        } else if ( this.agruparPor === 'idComprobante' ) {
            ordenamiento = [2, 0, 1, 3, 4, 5, 6];
        } else if ( this.agruparPor === 'fechaCreacionCorta' ) {
            ordenamiento = [7, 2, 0, 3, 4, 5, 6];
        }

        // Recorrer las cols en ordenamiento que hacen referencia a las columnas base y se agregan en el orden.
        ordenamiento.forEach( ( element ) => {
            const col = baseCol.find( ( x ) => x.order === element );

            if ( col.field === this.agruparPor ) {
                col.agrupa = true;
            }

            cols.push( col );
        } );

        return cols;
    }

    /**
     * Obtiene los datos, según filtros de fecha y de agrupación, de los movimientos desde la API.
     * @param lineaTocada Si existe alguna linea que ha sido tocada y esto es solo para refrescar se marcará como esNueva para que destaque
     */
    obtenerTreeMovimientos( lineaTocada: LineaComprobante[] = null ) {
        // Crear estructura de consulta
        const objConsulta = {
            orden: this.agruparPor,
            fechaInicio: new Date( this.rangoFechas[0] ),
            fechaFin: new Date( this.rangoFechas[1] ),
            usuario: this.auth.user.codUsuario,
            comprobante: 0,
            cuenta: 0,
        };

        // Solicitar los datos
        this.http
            .post( this.http.endpoint.movimientos, objConsulta )
            .subscribe( ( res ) => {
                if ( res.status === 200 && res.body != null ) {
                    this.datosBack = res.body.datos;

                    // Se agrega una nueva propiedad fecha corta para quitar el tiempo para el armarTreeNode
                    this.datosBack.forEach( ( dback ) => {
                        if ( dback.fechaCreacion ) {
                            dback.fechaCreacionCorta =
                                dback.fechaCreacion.substring( 0, 10 );
                        }
                    } );

                    // ok, filtrar resultados para marcar fila
                    if ( lineaTocada && lineaTocada.length > 0 ) {
                        lineaTocada.forEach( ( tocada ) => {
                            // Cuando se elimina un movimiento no se encontrará
                            const buscado = this.datosBack.find(
                                ( dato ) => + dato.idMov === + tocada.id
                            );
                            if ( buscado ) {
                                buscado.esNueva = true;
                            }
                        } );
                    }

                    this.iniciarTreeTable();
                }
            } );
    }

    /**
     * Agrupa los movimientos según selección de agrupacionesDisponibles
     */
    agrupar() {
        this.tt?.reset();
        this.cols = this.ordenaColumnasSegunAgrupacion();
        this.obtenerTreeMovimientos();
    }

    /**
     * Iniciar el armado de datos desde el 'datosBack' al arbol de 'arbolDeMovimientos'.
     * este armado depende del 'agruparPor' para que el encargado de cada grupo arme el arbol.
     */
    private iniciarTreeTable() {
        this.crearMenuContextual();

        // idMov es la key para no agrupar, por eso se crea una lista y no un árbol.
        if ( this.agruparPor === 'id' ) {
            this.arbolDeMovimientos = this.armarListNodes( this.datosBack );
        } else if ( this.agruparPor === 'cuenta' ) {
            this.arbolDeMovimientos = this.armarTreeNodesCuentas(
                this.datosBack
            );
        } else {
            this.arbolDeMovimientos = this.armarTreeNodes( this.datosBack );
        }
        this.totalizarArbolDeMovimientos();
    }

    /**
     * Genera lista de Treenodes a partir del array recibido
     * @param datos Datos a convertir en Treenode
     */
    armarListNodes( datos ): TreeNode[] {
        let treeMov: TreeNode;
        const nodos: TreeNode[] = [];

        // tslint:disable-next-line: forin
        for ( const key in datos ) {
            treeMov = this.crearEstructuraTreeNode( datos, key );
            nodos.push( treeMov );
        }
        return nodos;
    }

    /**
     * Función de datos recursiva para generar arreglos de Treenodes
     * genera la jerarquía según el campo que se esté agrupando (agruparPor)
     * @param datos Array de movimientos ordenados por algun campo agrupable
     */
    armarTreeNodesCuentas( datos: any[] ) {
        const listadoRaiz: TreeNode[] = [];
        let treeNodo: TreeNode;
        let treeMov: TreeNode;
        let claveactual = null;

        // tslint:disable-next-line: forin
        for ( const key in datos ) {
            // cambio de clave o primera vez, se agregan los hijos si no es la primera vez
            if ( claveactual !== datos[key].idCta ) {
                if ( treeNodo != null ) {
                    listadoRaiz.push( treeNodo );
                }

                const ladata = {
                    cuenta: this.listadoCuentas.find(
                        ( ctaa ) => ctaa.id === datos[key].idCta,
                        false
                    ),
                };
                // Se crea este nuevo con el nombre
                treeNodo = {
                    data: ladata,
                    children: [],
                    parent: null,
                };

                claveactual = ladata.cuenta.id;
            }

            // se crea treeMov
            treeMov = this.crearEstructuraTreeNode( datos, key );
            treeMov.parent = treeNodo;
            if ( ! treeNodo ) {
                treeNodo = {
                    data: {} as LineaComprobante,
                    children: [],
                    parent: null,
                };
                treeNodo.children = [];
            }
            treeNodo.children.push( treeMov );
        }

        if ( treeNodo != null ) {
            listadoRaiz.push( treeNodo );
        }
        return listadoRaiz;
    }

    /**
     * Función de datos recursiva para generar arreglos de Treenodes
     * genera la jerarquía según el campo que se esté agrupando (agruparPor)
     * @param datos Array de movimientos ordenados por algun campo agrupable
     */
    armarTreeNodes( datos: any[] ) {
        const listadoRaiz: TreeNode[] = [];
        let treeNodo: TreeNode;
        let treeMov: TreeNode;
        let claveactual = null;

        // tslint:disable-next-line: forin
        for ( const key in datos ) {
            // cambio de clave o primera vez, se agregan los hijos si no es la primera vez
            if ( claveactual !== datos[key][this.agruparPor] ) {
                if ( treeNodo != null ) {
                    listadoRaiz.push( treeNodo );
                }
                // Se crea este nuevo con el nombre
                treeNodo = {
                    data: {
                        [this.agruparPor]: datos[key][this.agruparPor],
                    },
                    children: [],
                    parent: null,
                };
                claveactual = datos[key][this.agruparPor];
            }

            // se crea treeMov
            treeMov = this.crearEstructuraTreeNode( datos, key );
            treeMov.parent = treeNodo;
            if ( ! treeNodo ) {
                treeNodo = {
                    data: {} as LineaComprobante,
                    children: [],
                    parent: null,
                };
                treeNodo.children = [];
            }
            treeNodo.children.push( treeMov );
        }

        if ( treeNodo != null ) {
            listadoRaiz.push( treeNodo );
        }

        return listadoRaiz;
    }

    /**
     * Genera un objeto de tipo treenode a partir de un arreglo de datos proveniente del back.
     * @param datos   Datos de origen
     * @param key     Key que representa la fila dentro del dato
     */
    crearEstructuraTreeNode( datos: any, key: string ) {
        const treeMov: TreeNode = {
            data: {
                id: datos[key].idMov,
                orden: datos[key].movOrden,
                idComprobante: datos[key].idComprobante,
                fechaCreacion: datos[key].fechaCreacion,
                fechaCreacionCorta: datos[key].fechaCreacion
                    ? datos[key].fechaCreacion.substring( 0, 10 )
                    : null,
                fechaModificacion: datos[key].fechaModificacion,
                cuenta: this.listadoCuentas.find(
                    ( cta ) => cta.id === datos[key].idCta,
                    false
                ),
                categoria: this.listadoCategorias.find(
                    ( cta ) => cta.id === datos[key].idCat,
                    false
                ),
                idMovRef: datos[key].idRef,
                monto: datos[key].monto,
                glosa: datos[key].movGlosa,
                codMoneda: datos[key].movCodMoneda,
                codComprobante: datos[key].codComprobante,
                menu: [],
                estaEditada: false,
                esNueva: datos[key].esNueva ? true : false,
                estaListaParaGrabar: false,
            } as LineaComprobante,
            children: [],
            parent: null,
        };
        if ( treeMov.data.esNueva ) {
            setInterval( () => {
                treeMov.data.esNueva = false;
            }, 9000 );
        }
        return treeMov;
    }

    /**
     * Respuesta del componente de agregar movimiento.
     * @param obj Respuesta emitida con el comprobante y sus lineas que se han generado
     */
    respuestaAgregarMovimiento( obj: { res: string; data: Comprobante } ) {
        if ( obj.res === 'OK' ) {
            const comprobante = obj.data;

            this.obtenerTreeMovimientos( comprobante.lineas );
        } else {
            this.ngOnInit();
        }
    }

    /**
     * Respuesta del componente de mover movimiento.
     * @param res Respuesta emitida con la líneas que se ha movido
     */
    respuestaMoverMovimiento( res ) {
        if ( res.status === 'OK' ) {
            this.obtenerTreeMovimientos( [res.data] );
        } else {
            this.ngOnInit();
        }
    }

    /**
     * Evento al seleccionar un movimiento
     * @param event Objeto evento que incluye el nodo en el data
     */
    eventoSeleccionDeMovimiento( event ) {
        this.totalizarSeleccionados();
    }

    /**
     * Evento al desseleccionar un movimiento
     * @param event Objeto evento que incluye el nodo en el data
     */
    eventoDesSeleccionDeMovimiento( event ) {
        this.totalizarSeleccionados();
    }

    /**
     * Realiza la suma de los montos de los movimientos seleccionados
     * solo suma los que no tienen hijos para no sumar los nodos padres que en su monto ya tienen el total
     */
    totalizarSeleccionados() {
        let res = 0;
        this.movimientosLotes.forEach( ( mov ) => {
            if ( mov.children.length === 0 ) {
                res += + mov.data.monto;
            }
        } );
        this.sumaLote = res;
    }

    /**
     * Evento que realiza el llamado al xlsx para crear el nuevo excel.
     */
    exportExcel() {
        import( 'xlsx' ).then( ( xlsx ) => {
            const worksheet = xlsx.utils.json_to_sheet( this.datosBack );
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
            };
            const excelBuffer: any = xlsx.write( workbook, {
                bookType: 'xlsx',
                type: 'array',
            } );
            this.saveAsExcelFile( excelBuffer, 'movimientos' );
        } );
    }

    /**
     * Guarda la descarga de los datos en un excel para descargar
     * @param buffer Contiene los datos del excel
     * @param fileName  Nombre del archivo de salida
     */
    saveAsExcelFile( buffer: any, fileName: string ): void {
        const EXCEL_TYPE =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob( [buffer], { type: EXCEL_TYPE } );
        FileSaver.saveAs(
            data,
            fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    /**
     * Despliega el dialog para poder mover un movimiento y llama al obtener listado comprobantes.
     */
    mostrarMoverMovimiento() {
        this.moverComprobante.mostrarMoverMovimiento(
            this.movimientoSeleccionado.data
        );
    }



}
