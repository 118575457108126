<br />


<p-table
    [value]="saldos"
    [styleClass]="sizeTable">
    <ng-template pTemplate="header">
        <tr>
            <th
                class="m-2"
                style="width: 60%"
                pSortableColumn="ctaNombre">
                Cuenta
                <p-sortIcon field="ctaNombre"></p-sortIcon>
            </th>
            <th
                class="text-right m-2"
                style="width: 40%"
                pSortableColumn="saldo">
                Saldo
                <p-sortIcon field="saldo"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-saldos>
        <tr class="filatabla">
            <td >{{ saldos.ctaNombre }}</td>
            <td class="text-right" colspan="2">
                {{ saldos.saldo | lineas : 'formatoMoneda' }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="grid">
            
            <div class="md:col-6 lg:col-6 flex justify-content-start">
                <p-checkbox [(ngModel)]="mostrarEnCero" [binary]="true" inputId="binary" (onChange)="actualizaMostrarSaldosEnCero($event)"></p-checkbox>
                <p class="text-xs pl-1">Mostrar saldos en cero</p>
            </div>
            <div class="md:col-6 lg:col-6 flex justify-content-end">
                <span class="">Total :</span>                
                 <span class="pl-3 underline">{{ saldoTotal | lineas : 'formatoMoneda' }}</span>
            </div>
           
        </div>
    </ng-template>
</p-table>
