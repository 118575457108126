import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { Cuenta } from '../interfaces/cuenta';
import { Usuario } from '../interfaces/usuario';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { ConfiguracionPagina } from '../interfaces/configuracion-pagina';

/**
 * Muestra mensajes al usuario desde cualquier componente y seta los titulos globalmente
 */

@Injectable( {
    providedIn: 'root',
} )
export class StateService {
    private objMensaje: Subject<any>;
    public objMensaje$: Observable<any>;

    // Subject para configurar el modulo de configuración
    public configuracionModulo: Subject<ConfiguracionPagina>;
    public configuracionModulo$: Observable<ConfiguracionPagina>;

    // Subject para las cuentas
    public listadoCuentasDisponibles: BehaviorSubject<Cuenta[]>;
    public listadoCuentasDisponibles$: Observable<Cuenta[]>;

    // Subject para los años del sistema
    public listadoAgnosDisponibles: BehaviorSubject<number[]>;
    public listadoAgnosDisponibles$: Observable<number[]>;

    public usuario: Usuario;
    public hoy: Date;

    public inicioAño: Date;
    public finAño: Date;
    public primerAgno: any;

    mensajestipo = {
        error: 'error',
        info: 'info',
        advertencia: 'warn',
        ok: 'success',
    };

    constructor(
        public http: HttpService,
        public authService: AuthenticationService
    ) {
        // Creo un subject y un Observable para suscribir y recibir los mensajes
        this.objMensaje = new Subject<any>();
        this.objMensaje$ = this.objMensaje.asObservable();

        // Creo un subject para recibir las configuraciones de cada página y el Observable para suscribir
        this.configuracionModulo = new Subject<ConfiguracionPagina>();
        this.configuracionModulo$ = this.configuracionModulo.asObservable();

        // Subject y observable para las cuentas
        this.listadoCuentasDisponibles = new BehaviorSubject<Cuenta[]>(
            [] as Cuenta[]
        );
        this.listadoCuentasDisponibles$ =
            this.listadoCuentasDisponibles.asObservable();

        this.listadoAgnosDisponibles = new BehaviorSubject<number[]>(
            [] as number[]
        );
        this.listadoAgnosDisponibles$ =
            this.listadoAgnosDisponibles.asObservable();

        // Me subscribo al auth current user para saber cuandose logea un usuario :)
        authService.currentUser.subscribe( ( x ) => {
            this.usuario = x;
            if ( x !== null ) {
                this.obtenerListadoCuentas();
                this.obtenerAgnosSistema();
            }
        } );

        // Establecemos las fechas necesarias
        this.hoy = new Date( Date.now() );
        // TODO acá mira desde el comienzo del 2020 que fue cuando nació el sistema
        this.inicioAño = new Date( this.hoy.getFullYear(), 0, 1 );
        this.finAño = new Date( this.hoy.getFullYear(), 12, 1 );
    }

    obtenerAgnosSistema() {
        const objConsulta = {
            codUsuario: this.authService.user.codUsuario,
        };
        // Solicitar los datos
        this.http
            .post( this.http.endpoint.detectaAgnosSistema, objConsulta )
            .subscribe( ( res ) => {
                if ( res.status === 200 && res.body != null ) {
                    const agnos: number[] = [];

                    res.body.agnos.forEach( ( ag ) => {
                        agnos.push( ag.agno );
                    } );

                    this.listadoAgnosDisponibles.next( agnos );

                    if ( agnos.length > 0 ) {
                        this.primerAgno = agnos[0];
                    }
                }
            } );
    }

    /**
     * Genera el listado de cuentas disponibles
     */
    obtenerListadoCuentas() {
        const resCta = this.http.get(
            this.http.endpoint.cuentasPorTipo + '/CTA'
        );
        const resCat = this.http.get(
            this.http.endpoint.cuentasPorTipo + '/CAT'
        );

        forkJoin( [resCta, resCat] ).subscribe( ( resList ) => {
            if ( resList[0].status === 200 && resList[1].status === 200 ) {
                const listado: Cuenta[] = [];
                resList[0].body.datos.forEach( ( cta: Cuenta ) => {
                    const cuenta = {
                        esIngreso: Boolean( + cta.esIngreso ),
                        codTipoCuenta: cta.codTipoCuenta,
                        codUsuario: cta.codUsuario,
                        fechaCreacion: cta.fechaCreacion,
                        nombre: cta.nombre,
                        id: cta.id,
                        color: cta.color,
                    } as Cuenta;
                    listado.push( cuenta );
                } );

                resList[1].body.datos.forEach( ( cta: Cuenta ) => {
                    const cuenta = {
                        esIngreso: Boolean( + cta.esIngreso ),
                        codTipoCuenta: cta.codTipoCuenta,
                        codUsuario: cta.codUsuario,
                        fechaCreacion: cta.fechaCreacion,
                        nombre: cta.nombre,
                        id: cta.id,
                        color: cta.color,
                    } as Cuenta;
                    listado.push( cuenta );
                } );

                this.listadoCuentasDisponibles.next( listado );
            } else {
                this.agregarMensaje(
                    this.mensajestipo.error,
                    ' Error en obtenerListadoCuentas',
                    resList[0].status + ' : ' + resList[0].statusText
                );
            }
        } );
    }

    /**
     * Agrega un mensaje para mostrar
     * @param modulo el nombre del módulo donde se produjo el error
     * @param mensaje el mensaje que se va a mostrar
     */
    agregarMensaje( tipo: string, modulo: string, mensaje: string ) {
        const objMensaje = {
            tipo,
            modulo,
            mensaje,
        };
        this.objMensaje.next( objMensaje );
    }

    /**
     * Encargado de setear en el objeto observable
     * @param titulo        Título a mostrar
     * @param mostrarMenu   Opción de mostrar menú.
     */
    configurarModulo( titulo: string, subtitulo: string, mostrarMenu: boolean ) {
        const configuracion = {
            titulo,
            subtitulo,
            mostrarMenu,
        } as ConfiguracionPagina;

        // Indicamos al subject que una nueva configuracion ha sido enviada al subject
        this.configuracionModulo.next( configuracion );
    }
}
