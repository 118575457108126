<p-card class="p-1">
<p-toolbar>
    <div class="p-toolbar-group-start">
        <!--<div class="flex flex-row flex-wrap">
                <div class="surface-card shadow-2 p-3 border-round flex flex-row flex-wrap">
                    <div class="flex justify-content-between mb-3 w-10rem">
                        <div>
                            <span class="block text-500 font-medium mb-3">Saldo</span>
                            <div class="text-900 font-medium text-xl">$15.000.502</div>
                        </div>
                    </div>
               
                    <div class="flex justify-content-between mb-3 w-10rem">
                        <div>
                            <span class="block text-500 font-medium mb-3">Variación diaria</span>
                            <div class="text-900 font-medium text-xl">$-15.000.502</div>
                        </div>
                    </div>
                        <p-button icon="pi pi-refresh text-orange-500" [link]="true" ></p-button>
                       
                </div>
        </div>-->
    </div>
    <div class="p-toolbar-group-end">
        <div class="flex flex-column p-flex-md-row">
            <div>
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-arrows-h"
                    class="p-button-rounded p-button-success p-1 m-1"
                    (click)="btnAgregarTransferencia()"
                    pTooltip="Agregar transferencia entre cuentas"
                    tooltipPosition="bottom"
                    tooltipZIndex="100000000"></button>
                
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded p-button-success p-1 m-1"
                    (click)="btnAgregarMovimiento()"
                    pTooltip="Agregar un nuevo movimiento"
                    tooltipZIndex="100000000"></button>
            </div>
        </div>
    </div>
</p-toolbar>
</p-card>

<div class="grid w-full">
    <div class="sm:col-12 md:col-6 lg:col-6">
        <div class="grid">
            <div class="col-12">
                <p-panel
                    header="Saldos consolidados"
                    [toggleable]="true">
                    <app-saldos
                        [tipo]="'CTA'"
                        [orden]="'desc'"
                        [invertir]="false"
                        #saldocta></app-saldos>
                </p-panel>
            </div>
            <div class="col-12">
                <p-panel
                    header="Evolución Anual"
                    [toggleable]="true">
                    <app-grafico-saldos-lineal
                        #grafico></app-grafico-saldos-lineal>
                </p-panel>
            </div>
        </div>
    </div>
    <div class="sm:col-12 md:col-6 lg:col-6">
        <p-panel
            header="Consolidados por categoría"
            [toggleable]="true">
            <app-saldos
                [tipo]="'CAT'"
                [orden]="'desc'"
                [invertir]="true"
                #saldocat></app-saldos>
        </p-panel>
    </div>
</div>

<app-agregar-movimientos
    (notify)="respuestaAgregarMovimiento($event)"
    #agregarmov></app-agregar-movimientos>



    