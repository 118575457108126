import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

/**
 * Servicio encargado de controlar los  errores no controlados para registrar el mensaje y
 * mostrarlo en la página de error
 */

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    /**
     * Error en el sistema
     */
    errorSistema: any;

    /**
     * Constructor del servicio
     * @param router Ruta actual
     */
    constructor(private router: Router) {}

    /**
     * Encargado de capturar los errores del sistema.
     */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error('ErrorService', error); // log to console instead

            const obj = {
                status: error.status,
                statusText: error.statusText,
            };

            this.errorSistema = error;

            if (error.status !== 401) {
                this.router.navigate(['/error']);
            } else {
                // no aut
                this.router.navigate(['/login']);
            }

            return of(obj as unknown as T);
        };
    }
}
