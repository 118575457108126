import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Comprobante } from '../interfaces/comprobante';
import { Saldoinicial } from '../interfaces/saldoinicial';
import { AuthenticationService } from '../servicios/authentication.service';
import { HttpService } from '../servicios/http.service';
import { StateService } from '../servicios/state.service';

/**
 * Componente que muestra y gestiona los saldos iniciales
 */
@Component({
    selector: 'app-saldos-iniciales',
    templateUrl: './saldos-iniciales.component.html',
    styleUrls: ['./saldos-iniciales.component.less'],
})
export class SaldosInicialesComponent implements OnInit {
    /**
     * Lista de años y saldos iniciales generados
     */
    saldosIniciales: Saldoinicial[];
    /**
     * Lista de años con datos en el sistema
     */
    listaAgnos: any;

    /**
     * Constructor del componente
     * @param http   Servicios Http
     * @param state  Servicio de estado
     * @param auth   Servicios de autenticación
     * @param route  Servicio de gestión de redirección
     * @param router Servicio de gestión de rutas
     */
    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.state.configurarModulo(
            'Saldos Iniciales',
            'Saldos iniciales del sistema',
            true
        );
    }

    /**
     * Oninit del componente donde se obtienen los años que poseen datos y se buscan los comprobantes de SI
     */
    ngOnInit(): void {
        // Obtener las cuentas y categorias
        this.state.listadoAgnosDisponibles$.subscribe((agnos) => {
            this.listaAgnos = agnos;
            this.buscaSaldosIniciales();
        });
    }

    /**
     * Busca los comprobantes de saldos iniciales que existen generados y
     * coteja la información con los diferentes años que existen datos en el sistema
     */
    private buscaSaldosIniciales() {
        const objConsulta = {
            codUsuario: this.auth.user.codUsuario,
        };

        // Solicitar los datos
        this.http
            .post(this.http.endpoint.obtenerSaldosIniciales, objConsulta)
            .subscribe((res) => {
                if (res.status === 200 && res.body != null) {
                    this.saldosIniciales = [];
                    // Recorro los años que tiene el sistema para buscar su comprobante de SI
                    this.listaAgnos.forEach((agno) => {
                        const saldoinicial = {} as Saldoinicial;
                        saldoinicial.agno = agno;
                        saldoinicial.comprobante = res.body.datos.find(
                            (com: Comprobante) =>
                                new Date(com.fechaCreacion).getFullYear() ===
                                +agno
                        );

                        if (!saldoinicial.comprobante) {
                            saldoinicial.comprobante = {} as Comprobante;
                            saldoinicial.comprobante.id = -1;
                            saldoinicial.comprobante.codComprobante = '';
                            saldoinicial.comprobante.fechaCreacion = null;
                        }

                        this.saldosIniciales.push(saldoinicial);
                    });
                }
            });
    }
    /**
     * Método que procesa el saldo inicial correspondiente a los datos de 1 año de datos
     * @param si Saldo Inicial que se va a procesar
     */
    procesarSaldoInicial(si: Saldoinicial) {
        this.http
            .post(this.http.endpoint.generarSaldosIniciales, {
                usuario: this.state.usuario.codUsuario,
                agno: +si.agno,
            })
            .subscribe((res) => {
                if (res.status === 200) {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.info,
                        'Saldo Inicial generado',
                        res.statusText
                    );
                    this.buscaSaldosIniciales();
                }
            });
    }

    /**
     * Método que envia a la página de mostrar comprobante con el comprobante seleccionado
     * @param comp Comprobante a mostrar
     */
    mostrarComprobante(comp: Saldoinicial) {
        this.router.navigate(['/comprobante', comp.comprobante.id], {
            relativeTo: this.route,
        });
    }
}
