import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerCuentasComponent } from './cuentas/ver-cuentas/ver-cuentas.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VerMovimientosComponent } from './movimientos/ver-movimientos/ver-movimientos.component';
import { PaginaErrorComponent } from './pagina-error/pagina-error.component';
import { PaginaLoginComponent } from './pagina-login/pagina-login.component';
import { AuthGuard } from './guards/auth.guard';
import { CrearComprobanteComponent } from './comprobantes/crear-comprobante/crear-comprobante.component';
import { SaldosInicialesComponent } from './saldos-iniciales/saldos-iniciales.component';
import { InformeMensualComponent } from './informes/informe-mensual/informe-mensual.component';
import { GraficoSaldosLinealComponent } from './widgets/grafico-saldos-lineal/grafico-saldos-lineal.component';
import { GraficoCategoriaComponent } from './informes/grafico-categoria/grafico-categoria.component';

/**
 * Rutas del sistema
 */
const routes: Routes = [
    { path: '', redirectTo: 'tablero', pathMatch: 'full' },
    {
        path: 'cuentas',
        component: VerCuentasComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'movimientos',
        component: VerMovimientosComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'tablero',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    { path: 'error', component: PaginaErrorComponent },
    { path: 'login', component: PaginaLoginComponent },
    {
        path: 'comprobante/:id',
        component: CrearComprobanteComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'comprobante',
        component: CrearComprobanteComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'saldosiniciales',
        component: SaldosInicialesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'informeMensual',
        component: InformeMensualComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'graficoSaldosLineal/:pagina',
        component: GraficoSaldosLinealComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'graficoCategoria',
        component: GraficoCategoriaComponent,
        canActivate: [AuthGuard],
    },
];

/**
 * Routing module con las rutas del sistema
 */
@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
