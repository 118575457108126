import { Component, OnInit } from '@angular/core';
import { LineasPipe } from 'src/app/pipes/lineas.pipe';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

interface Nodito {
    periodo: any;
    ctaTipo: any;
    monto: number;
}

/**
 * Informe gráfico mensual que permite ver los saldos acumulados y la variación de saldos de forma mensual
 */
@Component({
    selector: 'app-informe-mensual',
    templateUrl: './informe-mensual.component.html',
    styleUrls: ['./informe-mensual.component.less'],
})
export class InformeMensualComponent implements OnInit {
    /**
     * Datos del gráfico que contiene los labels y el dataset
     */
    saldosMensuales: { labels: any[]; datasets: any[] };
    /**
     * Opciones de configuración del gráfico
     */
    options: any;
    /**
     * Pipe para formateo de valores
     */
    pipes: LineasPipe;
    /**
     * Determina las opciones posibles para el tipo de informe puede ser Acumulado o Variación
     */
    opcionesInforme: { name: string; code: string }[];
    /**
     * Determina el tipo de informe puede ser Acumulado o Variación
     */
    opcionSeleccionado = 'AC';

    /**
     * Rango de fecha para los filtros del calendario
     */
    rangoFechas: Date[];

    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService
    ) {
        this.pipes = new LineasPipe();
        this.state.configurarModulo(
            'Saldos mensuales',
            'Gráfico mensual histórico',
            true
        );
    }

    ngOnInit(): void {
        this.seteaFechasIniciales();
        this.obtenerSaldosMensuales();
        this.opcionesInforme = [
            { name: 'Acumulado', code: 'AC' },
            { name: 'Variacion', code: 'VA' },
        ];

        // Change these settings to change the display for different parts of the X axis
        // grid configuiration
        this.options = {
            responsive: true,
            title: {
                display: true,
                text: 'Saldos',
                fontSize: 16,
                fontColor: '#ffffff',
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontColor: '#ffffff',
                },
            },
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        this.pipes = new LineasPipe();
                        return this.pipes.formatoMoneda(tooltipItem.value);
                    },
                },
            },
        };
    }

    /**
     * Establece las fechas del control de rango de fecha inicial
     * Inicializa el rango de fechas desde el primer día del mes pasado hasta hoy
     */
    seteaFechasIniciales() {
        this.rangoFechas = [];
        const actual = new Date();

        this.rangoFechas.push(this.state.inicioAño);
        this.rangoFechas.push(this.state.finAño);
    }

    /**
     * Obtiene los datos para el gráfico
     */
    obtenerSaldosMensuales() {
        const objEnviar = {
            tipo: 'CTA',
            usuario: this.auth.user.codUsuario,
            fechaHasta: new Date(this.rangoFechas[1]),
            fechaDesde: new Date(this.rangoFechas[0]),
        };

        this.http
            .post(this.http.endpoint.obtenerSaldosMensuales, objEnviar)
            .subscribe((res) => {
                if (res.status === 200) {
                    this.generarEstructuraGrafico(res);
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en obtenerSaldosMovimientosCuentas',
                        res.status + ' : ' + res.statusText
                    );
                }
            });
    }

    /**
     * Genera la estructura para que el gráfico funcione
     * El objeto res contiene todos los movimientos
     * @param res El objeto con la data obtenida
     */
    private generarEstructuraGrafico(res: any) {
        const saldos: Nodito[] = res.body.datos;

        // en cuentas se guardara el tipo de cuenta analizada
        const cuentas: any[] = [];

        // Creamos la estructura vacía del gráfico
        this.saldosMensuales = {
            labels: [],
            datasets: [],
        };

        // Recorre los saldos buscando las distintas fechas que existen en saldos y las deja en el array de labels (x)
        // además obtiene los distintos tipos de cuenta (y)

        saldos.forEach((mov: Nodito) => {
            if (!this.saldosMensuales.labels.includes(mov.periodo)) {
                this.saldosMensuales.labels.push(mov.periodo);
            }

            if (!cuentas.includes(mov.ctaTipo)) {
                cuentas.push(mov.ctaTipo);
            }
        });

        // Se realiza está operación de recorrido por cada tipo de cuenta para obtener los montos
        const ds = {
            label: 'Total',
            data: [],
            type: 'line',
            fill: false,
            borderColor: '#ffffff',
            backgroundColor: '#c0c0c0',
            showLine: true,
        };

        let saldosElegidos = [];

        // Cuando es acumulado se suman todos lo saldos menores a la fecha actual
        this.saldosMensuales.labels.forEach((fec: any) => {
            if (this.opcionSeleccionado === 'AC') {
                // todos los saldos menores a esta fecha
                saldosElegidos = saldos.filter((s) => s.periodo <= fec);
            } else {
                // todos los saldos de esta fecha
                saldosElegidos = saldos.filter((s) => s.periodo === fec);
            }

            let sumatoria = 0;
            saldosElegidos.forEach((todos: Nodito) => {
                sumatoria = sumatoria + +todos.monto;
            });
            ds.data.push(sumatoria);
        });

        this.saldosMensuales.datasets.push(ds);

        this.saldosMensuales.labels.forEach((lbl) => {
            lbl = lbl.substring(0, 7);
        });
    }

    /**
     * Setea fechas para abarcar todo el sistema
     */
    seteaFechasTodo() {
        this.rangoFechas = [];
        const actual = new Date();

        this.rangoFechas.push(new Date(this.state.primerAgno, 0, 1));
        this.rangoFechas.push(this.state.finAño);

        this.obtenerSaldosMensuales();
    }

    /**
     * Setea fechas para abarcar todo el sistema
     */
    seteaFechasAgno() {
        this.rangoFechas = [];

        this.rangoFechas.push(this.state.inicioAño);
        this.rangoFechas.push(this.state.finAño);

        this.obtenerSaldosMensuales();
    }
}
