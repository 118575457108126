<p-table [value]="saldosIniciales">
    <ng-template pTemplate="header">
        <tr>
            <th>Id</th>
            <th>Comprobante</th>
            <th>Fecha</th>
            <th>Año</th>
            <th>&nbsp;</th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-com>
        <tr>
            <td>{{ com.comprobante.id }}</td>
            <td>
                <button
                    pButton
                    pRipple
                    type="button"
                    class="p-button-rounded p-button-success p-button-text"
                    label="{{ com.comprobante.codComprobante }}"
                    (click)="mostrarComprobante(com)"></button>
            </td>
            <td>{{ com.comprobante.fechaCreacion }}</td>
            <td>{{ com.agno }}</td>
            <td>
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-cog"
                    (click)="procesarSaldoInicial(com)"
                    pTooltip="Procesar saldo inicial">
                    Procesar
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>
