<form
    [formGroup]="formMov"
    (ngSubmit)="eventoSubmit()"
    (keydown.enter)="eventoSubmit()">
    <p-dialog
        [(visible)]="seMuestraAgregarMovimiento"
        [modal]="true"
        [style]="{ width: '70%' }"
        [draggable]="true"
        [resizable]="true"
        [maximizable]="true"
        [dismissableMask]="true"
        >
        <ng-template pTemplate="header">
            <div class="grid justify-content-start" >
                
                <button *ngIf="this.comprobante.id"
                        pButton
                        type="button"
                        icon="pi pi-cog"
                        (click)="menu.toggle($event)"
                        class="p-button-text p-0"></button>
                
                        {{ tituloDinamico }}
                
            </div>

            <div class="grid justify-content-end" >
                <p-button [link]="true" (click)="this.router.navigate( ['/comprobante', this.comprobante.id] );">
                    <span class="badge text-xs justify-content-end" *ngIf="this.comprobante.id">
                        Comprobante {{ this.comprobante.id }} <span *ngIf="this.comprobante.codComprobante">- {{ this.comprobante.codComprobante }}</span>
                    </span>
                </p-button>
                   
                
            </div>
            
        </ng-template>

        <br />
        <div class="p-fluid p-formgrid grid p-0 m-0">
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <p-calendar
                        appendTo="body"
                        [showIcon]="true"
                        dateFormat="dd-mm-yy"
                        rangeSeparator="/"
                        [showButtonBar]="true"
                        [showTime]="true"
                        formControlName="fechaMovCalendar"
                        firstDayOfWeek = 1>
                    </p-calendar>
                    <label for="fechaMov">Fecha Ingreso</label>
                </span>
            </div>
            <div class="field col-12 md:col-6 text-right">
                <p-inputSwitch
                    formControlName="esIngreso"
                    name="esIngreso"></p-inputSwitch>
                <label
                    for="esIngreso"
                    class="col-fixed">
                    <span *ngIf="formMov.controls.esIngreso.value"
                        >Ingreso</span
                    >
                    <span *ngIf="!formMov.controls.esIngreso.value"
                        >Egreso</span
                    >
                </label>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <p-dropdown
                        appendTo="body"
                        [options]="listadoCuentas"
                        formControlName="ddListadoCuentas"
                        [autoDisplayFirst]="false"
                        optionLabel="nombre"
                        optionValue="id"
                        required>
                    </p-dropdown>
                    <label for="ddListadoCuentas">Cuenta </label>
                </span>
            </div>
            <div
                class="field col text-right"
                *ngIf="esTransferencia">
                <span class="p-float-label">
                    <p-dropdown
                        appendTo="body"
                        [options]="listadoCuentas"
                        [formControl]="
                            formMov.controls['ddListadoCuentasDestino']
                        "
                        [autoDisplayFirst]="false"
                        optionLabel="nombre"
                        optionValue="id"
                        required
                        *ngIf="esTransferencia">
                    </p-dropdown>
                    <label for="ddListadoCuentas">Cuenta destino</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <p-dropdown
                        appendTo="body"
                        [options]="listadoCategorias"
                        formControlName="ddListadoCategorias"
                        [autoDisplayFirst]="false"
                        [style]="{ width: '100%' }"
                        optionLabel="nombre"
                        optionValue="id"
                        required
                        (onChange)="aplicarCategoriaIngreso($event.value)">
                    </p-dropdown>
                    <label for="ddListadoCategorias">Categor&iacute;a</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <p-inputNumber
                        formControlName="monto"
                        [min]="0"
                        mode="currency"
                        currency="CLP"
                        required>
                    </p-inputNumber>
                    <label for="monto">Monto</label>
                </span>
            </div>
            <div class="field col-12 md:col-12 text-right">
                <span class="p-float-label">
                    <textarea
                        id="glosa"
                        formControlName="glosa"
                        rows="5"
                        pInputTextarea
                        autoResize="autoResize"
                        style="min-height: 200px"
                        maxlength="500"
                        required
                        appValidaInputs></textarea>
                    <label for="glosa">Glosa</label>
                </span>

                <span *ngIf="this.formMov.controls.glosa.value; else noGlosa">
                    {{ this.formMov.controls.glosa.value.length }}
                </span>
                <ng-template #noGlosa> 0 </ng-template>
                /500
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="grid">
                <div
                    *ngIf="
                        this.comprobante &&
                        !this.comprobante.nuevo &&
                        this.comprobante.lineas[0].fechaModificacion
                    "
                    class="col-12 p-text-italic text-xs">
                    Modificado el :
                    {{
                        this.comprobante.lineas[0].fechaModificacion
                            | date : 'full'
                    }}
                </div>
            </div>
            <div class="grid">
                <div class="col text-left">
                    <p-button
                        (click)="close()"
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        styleClass="p-button-text"></p-button>
                </div>
                <div class="col text-right">
                    <p-button
                        [disabled]="formMov.invalid || formMov.pristine"
                        type="submit"
                        label="Guardar"
                        icon="pi pi-check"
                        styleClass="p-button-text"></p-button>
                </div>
            </div>
        </ng-template>
    </p-dialog>
</form>

<app-mover-movimientos
    (notify)="respuestaMoverMovimiento($event)"></app-mover-movimientos>

    <p-tieredMenu
    #menu
    [model]="menuOpcionesComprobante"
    [popup]="true"></p-tieredMenu>