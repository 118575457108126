import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ChartModule } from 'primeng/chart';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeTableModule } from 'primeng/treetable';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToolbarModule } from 'primeng/toolbar';
import { InputMaskModule } from 'primeng/inputmask';
import { InplaceModule } from 'primeng/inplace';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FieldsetModule } from 'primeng/fieldset';
import { PasswordModule } from 'primeng/password';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DividerModule } from 'primeng/divider';
import { ValidaGlosaValidator } from './validadores/valida-glosa-validator';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';

import { SidebarModule } from 'primeng/sidebar';

import { AppComponent } from './app.component';
import { MenubarComponent } from './menubar/menubar.component';
import { VerCuentasComponent } from './cuentas/ver-cuentas/ver-cuentas.component';
import { CrearCuentasModalComponent } from './cuentas/crear-cuentas-modal/crear-cuentas-modal.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { VerMovimientosComponent } from './movimientos/ver-movimientos/ver-movimientos.component';
import { PaginaErrorComponent } from './pagina-error/pagina-error.component';
import { AgregarMovimientosComponent } from './movimientos/agregar-movimientos/agregar-movimientos.component';
import { SaldosComponent } from './widgets/saldos/saldos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CrearComprobanteComponent } from './comprobantes/crear-comprobante/crear-comprobante.component';
import { LineasPipe } from './pipes/lineas.pipe';
import { AuthInterceptorService } from './servicios/auth-interceptor.service';
import { PaginaLoginComponent } from './pagina-login/pagina-login.component';
import { AppConfigService } from './servicios/app-config.service';
import { GraficoSaldosLinealComponent } from './widgets/grafico-saldos-lineal/grafico-saldos-lineal.component';
import { MoverMovimientosComponent } from './movimientos/mover-movimientos/mover-movimientos.component';
import { TabViewModule } from 'primeng/tabview';
import localesEs from '@angular/common/locales/es-419';
import { registerLocaleData } from '@angular/common';
import { ConfirmaEliminarMovimientosComponent } from './dialog/confirma-eliminar-movimientos/confirma-eliminar-movimientos.component';
import { SaldosInicialesComponent } from './saldos-iniciales/saldos-iniciales.component';
import { BuscarComprobanteComponent } from './comprobantes/buscar-comprobante/buscar-comprobante.component';
import { InformeMensualComponent } from './informes/informe-mensual/informe-mensual.component';
import { GraficoCategoriaComponent } from './informes/grafico-categoria/grafico-categoria.component';


registerLocaleData(localesEs, 'es');

/**
 * Funcion que carga la configuración del archivo Json según la config solicitada
 * @param config Configuración solicitada
 * @returns Retorna la configuración
 */
export function servicesOnRun(
    config: AppConfigService
): () => Promise<boolean> {
    return () => config.load();
}

@NgModule({
    declarations: [
        AppComponent,
        MenubarComponent,
        VerCuentasComponent,
        CrearCuentasModalComponent,
        MensajesComponent,
        VerMovimientosComponent,
        PaginaErrorComponent,
        AgregarMovimientosComponent,
        SaldosComponent,
        DashboardComponent,
        CrearComprobanteComponent,
        LineasPipe,
        PaginaLoginComponent,
        GraficoSaldosLinealComponent,
        ValidaGlosaValidator,
        MoverMovimientosComponent,
        ConfirmaEliminarMovimientosComponent,
        SaldosInicialesComponent,
        BuscarComprobanteComponent,
        InformeMensualComponent,
        GraficoCategoriaComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserModule,
        MenuModule,
        MenubarModule,
        BrowserAnimationsModule,
        InputTextModule,
        ButtonModule,
        CardModule,
        TreeTableModule,
        DialogModule,
        TableModule,
        DropdownModule,
        ReactiveFormsModule,
        MessagesModule,
        MessageModule,
        InputNumberModule,
        ToastModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        CalendarModule,
        TooltipModule,
        BreadcrumbModule,
        ToolbarModule,
        SelectButtonModule,
        InputMaskModule,
        InplaceModule,
        ContextMenuModule,
        FieldsetModule,
        PasswordModule,
        PanelModule,
        FormsModule,
        RadioButtonModule,
        InputSwitchModule,
        ChartModule,
        TagModule,
        InputTextareaModule,
        TieredMenuModule,
        DividerModule,
        ToggleButtonModule,
        TabViewModule,
        ColorPickerModule,
        CheckboxModule,
        SidebarModule,
        NgHttpLoaderModule.forRoot(),
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: servicesOnRun,
            deps: [AppConfigService],
            multi: true,
        },
        {
            // Utilizado para interceptar las peticiónes HTTP con el token.
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: ConfirmationService },
        { provide: MessageService },
        { provide: LOCALE_ID, useValue: 'Es' },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
