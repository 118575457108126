import { Component, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { StateService } from './servicios/state.service';
import { ConfiguracionPagina } from './interfaces/configuracion-pagina';

@Component( {
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
} )
/**
 * Al ser el componente raíz posee el título desde variable observable por los demás componente que la setean
 */
export class AppComponent {

    state = inject( StateService );
    configuracionPagina: ConfiguracionPagina = {
        titulo: '',
        subtitulo: '',
        mostrarMenu: false
    };

    constructor( private config: PrimeNGConfig ) {
        // Me suscribo al observable del servicio para actualizar la configuración cada vez que cambie
        // Cuando el subject del observable genere un next

        this.state.configuracionModulo$.subscribe( ( configuracion: ConfiguracionPagina ) => {
            this.configuracionPagina.titulo = configuracion.titulo;
            this.configuracionPagina.subtitulo = configuracion.subtitulo;
            this.configuracionPagina.mostrarMenu = configuracion.mostrarMenu;
        } );

        this.config.setTranslation( {
            dayNames: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
            ],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            today: 'Hoy',
            clear: 'Limpiar',
            monthNames: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            monthNamesShort: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ],
        } );
    }
}
