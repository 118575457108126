<p-dialog
    header="Mover movimiento entre comprobantes"
    [(visible)]="seMuestraMoverMovimiento"
    [modal]="true"
    [style]="{ width: '70%' }">
    <p-divider></p-divider>
    <br/>

    <p-table
        [value]="movimiento"
        responsiveLayout="scroll"
        *ngIf="movimiento">
        <ng-template pTemplate="caption">
            <div class="field">
                Comprobante actual:
                {{ this.lineaOrigen?.codComprobante + '-' + this.lineaOrigen?.idComprobante  }}
            </div>
            <div class="field">
                <label for="ddComprobantes">Comprobante destino</label>
                <p-dropdown
                    appendTo="body"
                    [options]="listadoComprobantes"
                    [(ngModel)]="comprobanteDestino"
                    [autoDisplayFirst]="false"
                    optionLabel="id"
                    [filter]="true"
                    filterBy="codComprobante,id"
                    [showClear]="true"
                    placeholder="Seleccione un comprobante"
                    class="w-6 p-1 mt-2"
                    required
                    #ddComprobantes>
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="comprobanteDestino">
                            <div>
                                {{ comprobanteDestino.codComprobante }}
                                <span *ngIf="comprobanteDestino.codComprobante">-</span>
                                {{ comprobanteDestino.id }} -
                                {{ comprobanteDestino.fechaCreacion }}
                            </div>
                        </div>
                    </ng-template>
                    <ng-template
                        let-com
                        pTemplate="item">
                        <div>
                            <div>
                                {{ com.codComprobante }}
                                <span *ngIf="com.codComprobante">-</span> {{ com.id }} -
                                {{ com.fechaCreacion }}
                            </div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Fecha</th>
                <th>Comprobante</th>
                <th>Cuenta</th>
                <th>Categoría</th>
                <th>Glosa</th>
                <th>Monto</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-mov>
            <tr>
                <td>{{ mov.fechaCreacionCorta }}</td>
                <td>
                    <ng-container
                        *ngIf="!mov.codComprobante; else elseComprobante">
                        {{ mov.idComprobante }}
                    </ng-container>
                    <ng-template #elseComprobante>
                        {{ mov.codComprobante }}
                    </ng-template>
                </td>
                <td>{{ mov.cuenta.nombre }}</td>
                <td>{{ mov.categoria.nombre }}</td>
                <td>{{ mov.glosa }}</td>
                <td class="text-right">
                    {{ mov.monto | lineas : 'formatoMoneda' }}
                </td>
            </tr>
        </ng-template>

    </p-table>

    
 
    <br>
    

    <p-footer>
        <button
            pButton
            label="Mover"
            (click)="btnCambiarMovimientoComprobante()"
            [disabled]="!comprobanteDestino"></button>
    </p-footer>
</p-dialog>
