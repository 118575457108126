import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LineasPipe } from 'src/app/pipes/lineas.pipe';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

interface Nodito {
    fechaMov: any;
    ctaTipo: any;
    monto: number;
    nombre: string;
    color: string;
}

interface CuentaLabel {
    ctaTipo: any;
    nombre: string;
    color: string;
}

@Component( {
    selector: 'app-grafico-saldos-lineal',
    templateUrl: './grafico-saldos-lineal.component.html',
    styleUrls: ['./grafico-saldos-lineal.component.less'],
} )
export class GraficoSaldosLinealComponent implements OnInit {
    saldosDiarios: any;
    options: any;
    pipes: LineasPipe;
    consolidado = true;
    datos: any;
    inicio;
    agnoGrafico: number;

    /**
     * Rango de fecha para los filtros del calendario
     */
    rangoFechas: Date[];

    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService,
        private route: ActivatedRoute
    ) {
        this.seteaFechasIniciales();
        this.obtenerSaldosMovimientosCuentas();
        this.pipes = new LineasPipe();

        if ( this.route.snapshot.params.pagina ) {
            this.state.configurarModulo(
                'Saldos diarios',
                'Gráfico diario del año actual',
                true
            );
        }


    }

    seteaFechasIniciales() {
        this.rangoFechas = [];

        this.rangoFechas.push( this.state.inicioAño );
        this.rangoFechas.push( this.state.finAño );
    }

    ngOnInit(): void {}

    /**
     * Obtiene los datos para el gráfico, los movimientos se consideran en el transcurso de 1 año desde la fecha actual.
     */
    obtenerSaldosMovimientosCuentas() {


        const objEnviar = {
            tipo: 'CTA',
            usuario: this.auth.user.codUsuario,
            fechaDesde: new Date( this.rangoFechas[0] ),
            fechaHasta: new Date( this.rangoFechas[1] ),
        };

        this.http
            .post( this.http.endpoint.movimientosSaldoTipo, objEnviar )
            .subscribe( ( res ) => {
                if ( res.status === 200 ) {
                    this.datos = res;
                    this.generarEstructuraGrafico( this.datos );
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en obtenerSaldosMovimientosCuentas',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Genera la estructura para que el gráfico funcione
     * El objeto res contiene todos los movimientos
     * @param res El objeto con la data obtenida
     */
    private generarEstructuraGrafico( res: any ) {
        const saldos: Nodito[] = res.body.datos;
        // en cuentas se guardara el tipo de cuenta analizada
        const cuentas: any[] = [];

        // Creamos la estructura vacía del gráfico
        this.saldosDiarios = {
            labels: [],
            datasets: [],
        };

        // Recorre los saldos buscando las distintas fechas que existen en saldos y las deja en el array de labels (x)
        // además obtiene los distintos tipos de cuenta (y)
        // const saldosAño = saldos;
        // const saldosAño = saldos.filter(
        //    (s) => new Date(s.fechaMov) >= this.inicio
        // );

        saldos.forEach( ( mov: Nodito ) => {
            if ( ! this.saldosDiarios.labels.includes( mov.fechaMov ) ) {
                this.saldosDiarios.labels.push( mov.fechaMov );
            }

            if ( ! cuentas.includes( mov.ctaTipo ) ) {
                cuentas.push( mov.ctaTipo );
            }
        } );

        // Se realiza está operación de recorrido por cada tipo de cuenta para obtener los montos
        cuentas.forEach( ( sal ) => {
            const ds = {
                label: sal,
                data: [],
                type: 'line',
                fill: true,
                borderColor: '#ffffff',
                backgroundColor: '#616161',
            };

            this.saldosDiarios.labels.forEach( ( fec: any ) => {
                // obtengo todos los saldos menores a esta fecha
                const saldosMenores = saldos.filter( ( s ) => s.fechaMov <= fec );
                let totalCtaAcum = 0;

                saldosMenores.forEach( ( todos: Nodito ) => {
                    totalCtaAcum = totalCtaAcum + + todos.monto;
                } );
                ds.data.push( totalCtaAcum );
            } );
            this.saldosDiarios.datasets.push( ds );
        } );

        // Change these settings to change the display for different parts of the X axis
        // grid configuiration

        this.options = {
            responsive: true,
            title: {
                display: true,
                text: 'Saldos consolidados último año',
                fontSize: 16,
                fontColor: '#ffffff',
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontColor: '#ffffff',
                },
            },
            tooltips: {
                callbacks: {
                    label( tooltipItem, data ) {
                        this.pipes = new LineasPipe();
                        return this.pipes.formatoMoneda( tooltipItem.value );
                    },
                },
            },
        };
    }

    /**
     * Genera la estructura para que el grafico funcione
     * @param res El objeto con la data obtenida
     */
    private generarEstructuraGraficoPorCuenta( res: any ) {
        const saldos: Nodito[] = res.body.datos;
        // en cuentas se guardara el tipo de cuenta analizada
        const cuentas: CuentaLabel[] = [];

        // Creamos la estructura vacía del gráfico
        this.saldosDiarios = {
            labels: [],
            datasets: [],
        };

        // Recorre los saldos buscando las distintas fechas que existen en saldos y las deja en el array de labels (x)
        // además obtiene los distintos tipos de cuenta (y)
        // Para solo mostrar el año filtro los labels por inicioaño
        const saldosAño = saldos.filter(
            ( s ) => new Date( s.fechaMov ) >= this.state.inicioAño
        );

        saldosAño.forEach( ( mov: Nodito ) => {
            if ( ! this.saldosDiarios.labels.includes( mov.fechaMov ) ) {
                this.saldosDiarios.labels.push( mov.fechaMov );
            }
            const ctaL = {
                ctaTipo: mov.ctaTipo,
                nombre: mov.nombre,
                color: mov.color,
            } as CuentaLabel;

            if ( ! cuentas.some( ( item ) => item.nombre === ctaL.nombre ) ) {
                cuentas.push( ctaL );
            }
        } );

        // Se realiza está operación de recorrido por cada tipo de cuenta para obtener los montos
        cuentas.forEach( ( sal ) => {
            const ds = {
                label: sal.nombre,
                data: [],
                type: 'line',
                fill: true,
                borderColor: sal.color,
            };

            this.saldosDiarios.labels.forEach( ( fec: any ) => {
                // obtengo todos los saldos menores a esta fecha
                const saldosMenores = saldos.filter(
                    ( s ) => s.fechaMov <= fec && s.nombre === sal.nombre
                );
                let totalCtaAcum = 0;

                saldosMenores.forEach( ( todos: Nodito ) => {
                    totalCtaAcum = totalCtaAcum + + todos.monto;
                } );
                ds.data.push( totalCtaAcum );
            } );
            this.saldosDiarios.datasets.push( ds );
        } );

        // Change these settings to change the display for different parts of the X axis
        // grid configuiration
        const DISPLAY = true;
        const BORDER = true;
        const CHART_AREA = true;
        const TICKS = true;

        this.options = {
            responsive: true,
            title: {
                display: true,
                text: 'Saldos diarios último año',
                fontSize: 16,
            },
            legend: {
                position: 'bottom',
            },
            tooltips: {
                callbacks: {
                    label( tooltipItem, data ) {
                        this.pipes = new LineasPipe();
                        return this.pipes.formatoMoneda( tooltipItem.value );
                    },
                },
            },
        };
    }

    /**
     * Cambia el tipo de gráfico que se muestra
     */
    cambiaGrafico() {
        if ( this.consolidado ) {
            this.generarEstructuraGrafico( this.datos );
        } else {
            this.generarEstructuraGraficoPorCuenta( this.datos );
        }
    }
}
