<p-toolbar>
    <div class="p-toolbar-group-start">
        <div class="grid">
            <div class="sm:col-12 md:col-4 lg:col-6">
                <p-calendar
                    [(ngModel)]="rangoFechas"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="rango"
                    [showIcon]="true"
                    dateFormat="dd-mm-yy"
                    rangeSeparator="/"
                    [inputStyle]="{ width: '100%' }"
                    [showButtonBar]="true"
                    firstDayOfWeek="1"
                    styleClass="p-calendar-sm">
                </p-calendar>
            </div>
            <div class="sm:col-12 md:col-2 lg:col-2">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-search"
                    class="p-button-rounded p-button-success"
                    (click)="obtenerSaldosMensuales()"
                    pTooltip="Filtrar"
                    tooltipPosition="right"
                    tooltipZIndex="100000000"></button>
            </div>
            <div class="sm:col-12 md:col-6 lg:col-2">
                <button
                    pButton
                    type="button"
                    label="Todo"
                    icon="pi pi-check"
                    class="p-button-sm"
                    (click)="seteaFechasTodo()"></button>
            </div>
            <div class="sm:col-12 md:col-6 lg:col-2">
                <button
                    pButton
                    type="button"
                    label="Año"
                    icon="pi pi-check"
                    class="p-button-sm"
                    (click)="seteaFechasAgno()"></button>
            </div>
        </div>
    </div>
    <div class="p-toolbar-group-end">
        <div class="flex flex-column ms:flex-row">
            <div class="mb-2 mr-2">
                <p-selectButton
                    [options]="opcionesInforme"
                    [(ngModel)]="opcionSeleccionado"
                    optionValue="code"
                    optionLabel="name"></p-selectButton>
            </div>
        </div>
    </div>
</p-toolbar>

<p-card>
    <p-chart
        type="line"
        [data]="saldosMensuales"
        [options]="options"></p-chart>
    <!--<ng-template pTemplate="footer">
        <div style="text-align: center;">
            <p-toggleButton onLabel = "Por cuenta" offLabel="Consolidado" (onChange)="cambiaGrafico()"
             [(ngModel)]="consolidado"></p-toggleButton>
        </div>
    </ng-template>-->
</p-card>
