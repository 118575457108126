<p-toolbar>
    <div class="p-toolbar-group-start"></div>

    <div class="p-toolbar-group-end">
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-success"
            (click)="crearCuenta()"
            pTooltip="Agregar nueva cuenta o categoría"
            tooltipPosition="bottom"
            tooltipZIndex="100000000"></button>
    </div>
</p-toolbar>

<div class="col-12">
    <p-table
        [value]="listado"
        styleClass="p-datatable-sm"
        (onRowSelect)="editaCuentaCategoria($event.data)"
        selectionMode="single"
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 20, 30, 40, 50]"
        currentPageReportTemplate="Mostrando desde el {first} al {last} de {totalRecords} cuentas"
        [showCurrentPageReport]="true"
        [resizableColumns]="true"
        [globalFilterFields]="['nombre', 'codTipoCuenta']"
        [contextMenu]="cmfiLA"
        [(contextMenuSelection)]="cuentaSelecionada"
        #dt1>
        <ng-template pTemplate="caption">
            <div class="flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                        pInputText
                        class="p-inputtext-sm"
                        type="text"
                        (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                        "
                        placeholder="Buscar" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">
                    Id<p-sortIcon field="code"></p-sortIcon>
                </th>
                <th pSortableColumn="nombre">
                    Descripci&oacute;n
                    <p-sortIcon field="code"> </p-sortIcon>
                </th>
                <th pSortableColumn="codTipoCuenta">
                    Tipo Cuenta<p-sortIcon field="code"></p-sortIcon>
                </th>
                <th pSortableColumn="esIngreso">
                    Tipo Movimientos<p-sortIcon field="code"></p-sortIcon>
                </th>
                <th pSortableColumn="fechaCreacion">
                    Fecha Modificaci&oacute;n<p-sortIcon
                        field="code"></p-sortIcon>
                </th>
                <th>&nbsp;</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-cuenta>
            <tr
                [pSelectableRow]="cuenta"
                [pContextMenuRow]="cuenta">
                <td class="p-0">
                    {{ cuenta.id }}
                </td>
                <td class="p-0">
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="p-button-rounded p-button-success p-button-text"
                        label="{{ cuenta.nombre }}"
                        (click)="editaCuentaCategoria(cuenta)"
                        pTooltip="Editar"></button>
                </td>
                <td class="p-0">{{ cuenta.codTipoCuenta }}</td>
                <td class="p-0">
                    <span *ngIf="cuenta.esIngreso">Ingreso</span>
                    <span *ngIf="!cuenta.esIngreso">Egreso</span>
                </td>
                <td class="p-0">{{ cuenta.fechaCreacion }}</td>
                <td
                    [ngStyle]="{ 'background-color': cuenta.color }"
                    class="p-0">
                    &nbsp;
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu
        #cmfiLA
        [model]="itemsMenuFila"></p-contextMenu>
</div>

<app-crear-cuentas-modal
    (notify)="actualizaCuentas($event)"></app-crear-cuentas-modal>
