import { Component, ViewChild } from '@angular/core';
import { AgregarMovimientosComponent } from '../movimientos/agregar-movimientos/agregar-movimientos.component';
import { StateService } from '../servicios/state.service';
import { SaldosComponent } from '../widgets/saldos/saldos.component';
// import { version } from '../../../angular.json';
import { GraficoSaldosLinealComponent } from '../widgets/grafico-saldos-lineal/grafico-saldos-lineal.component';
import { AuthenticationService } from '../servicios/authentication.service';

/**
 * Dashboard de la aplicación que muestra graficos y saldos junto a un toolbar para acceder a agregar movimientos y transferencias
 */
@Component( {
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
} )
export class DashboardComponent {
    /**
     * Modal para agregar movimientos o transferencias.
     */
    @ViewChild( AgregarMovimientosComponent )
        agregarMovComponent: AgregarMovimientosComponent;

    /**
     * tabla de saldos de cuentas
     */
    @ViewChild( 'saldocta' ) saldoCta: SaldosComponent;

    /**
     * Tabla de saldos de categorías
     */
    @ViewChild( 'saldocat' ) saldoCat: SaldosComponent;

    /**
     * Grafico de saldos
     */
    @ViewChild( 'grafico' ) grafico: GraficoSaldosLinealComponent;

    /**
     * Indica si el grafico muestra variación anual
     */
    variacionAnual = false;

    /**
     * Versión del sistema
     */
    version = 1;

    /**
     * Constructor encargado de configurar el modulo con el titulo y la versión
     * @param state Servicio de estado
     * @param auth Servicio de autenticación
     */
    constructor(
        private state: StateService,
        private auth: AuthenticationService
    ) {
        this.state.configurarModulo(
            `Bienvenido Nuevamente ${this.auth.user.codUsuario}`,
            `Dashboard Versión ${this.version} `,
            true
        );
    }


    /**
     * Muestra ventana de crear movimiento sin comprobante
     */
    btnAgregarMovimiento() {
        this.agregarMovComponent.mostrarAgregarMovimiento();
    }

    /**
     * Despliega el modal para agregar nueva transferencia.
     */
    btnAgregarTransferencia() {
        this.agregarMovComponent.mostrarAgregarTransferencia();
    }
    /**
     * Método que se ejecutar luego de agregar un movimiento
     * @param objRes Objeto respuesta
     */
    respuestaAgregarMovimiento( objRes: { res: string } ) {
        if ( objRes.res === 'OK' ) {
            this.saldoCta.obtenerSaldosMovimientosCuentas();
            this.saldoCat.obtenerSaldosMovimientosCuentas();
            this.grafico.obtenerSaldosMovimientosCuentas();
        }
    }

    /**
     * Cambia  tipo de variación que muestra el gráfico
     */
    cambiaGrafico() {
        this.variacionAnual = true;
    }
}
