import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

/**
 * Servicio para la gestión del JWT
 */
@Injectable({
    providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
    /**
     * Constructor que injecta el servicios
     * @param authService Servicio de autenticacón
     */
    constructor(private authService: AuthenticationService) {}

    /**
     * Se encarga de intenceptar TODAS las peticiones a los end points, inyectar el token almacenado antes de pasarla a la siguiente.
     * @param req El objeto de solicitud saliente que se debe manejar.
     * @param next El siguiente interceptor de la cadena, o el backend si no quedan interceptores en la cadena.
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let request = req;
        const token = this.authService.obtenerToken();

        // Si tenemos token clonamos el HttpRequest para poder modificar.
        if (token) {
            // Se agrega el token
            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${token}`,
                },
            });
        }

        // Retornar el Observable correspondiente con el request
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                // Retorna evento : Un observable del flujo de eventos.
                return event;
            })
        );
    }
}
