<p-dialog
    header="Buscar comprobante"
    [(visible)]="seMuestraBuscarComprobante"
    [modal]="true"
    [style]="{ width: '60%', height: '60%' }">
    <p-divider></p-divider>
    <span class="p-float-label">
        <p-dropdown
            [style]="{ width: '80%' }"
            [options]="listadoAgnos"
            [(ngModel)]="agnoSeleccionado"
            [autoDisplayFirst]="false"
            (onChange)="this.obtenerListadoComprobantes()"
            #ddagno
            required>
        </p-dropdown>
        <label for="ddagno">Seleccione año comprobante</label>
    </span>
    <br />
    <br />
    <br />
    <span class="p-float-label">
        <p-dropdown
            [style]="{ width: '80%' }"
            [options]="listadoComprobantes"
            [(ngModel)]="comprobanteSeleccionado"
            [autoDisplayFirst]="false"
            optionLabel="id"
            [filter]="true"
            filterPlaceholder="Buscar"
            emptyFilterMessage="No existen comprobantes para los filtros ingresados"
            filterBy="codComprobante,id"
            [disabled]="!this.agnoSeleccionado"
            required
            #ddComprobantes>
            <ng-template pTemplate="selectedItem">
                <div *ngIf="comprobanteSeleccionado">
                    <div>
                        {{ comprobanteSeleccionado.codComprobante }}
                        <span *ngIf="comprobanteSeleccionado.codComprobante"
                            >-</span
                        >
                        {{ comprobanteSeleccionado.id }} -
                        {{ comprobanteSeleccionado.fechaCreacion }}
                    </div>
                </div>
            </ng-template>
            <ng-template
                let-com
                pTemplate="item">
                <div>
                    <div>
                        {{ com.codComprobante }}
                        <span *ngIf="com.codComprobante">-</span> {{ com.id }} -
                        {{ com.fechaCreacion }}
                    </div>
                </div>
            </ng-template>
        </p-dropdown>
        <label for="ddComprobantes">Seleccione el comprobante</label>
    </span>

    <p-footer>
        <button
            pButton
            label="Ver"
            (click)="btnVerComprobante()"
            [disabled]="
                !comprobanteSeleccionado || !comprobanteSeleccionado.id
            "></button>
    </p-footer>
</p-dialog>
