<p-card class="p-1">
<p-toolbar>
    <div class="p-toolbar-group-start">
        <div class="grid">
            <div class="sm:col-12 md:col-10 lg:col-10">
                <p-calendar
                    [(ngModel)]="rangoFechas"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="rango"
                    [showIcon]="true"
                    dateFormat="dd-mm-yy"
                    rangeSeparator="/"
                    [inputStyle]="{ width: '100%' }"
                    [showButtonBar]="true"
                    firstDayOfWeek="1"
                    styleClass="p-calendar-sm">
                </p-calendar>
            </div>
            <div class="sm:col-12 md:col-2 lg:col-2">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-search"
                    class="p-button-rounded bg-primary"
                    (click)="obtenerTreeMovimientos()"
                    pTooltip="Filtrar movimientos"
                    tooltipPosition="right"
                    tooltipZIndex="100000000"></button>
            </div>
        </div>
    </div>
    <div class="p-toolbar-group-end">
        <div class="flex flex-column ms:flex-row">
            <div>
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-file-excel"
                    (click)="exportExcel()"
                    class="p-button-rounded bg-primary p-1 m-1"
                    pTooltip="Exportar a XLS"
                    tooltipPosition="bottom"></button>
                
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-arrows-h"
                    class="p-button-rounded bg-primary p-1 m-1"
                    (click)="btnAgregarTransferencia()"
                    pTooltip="Agregar transferencia entre cuentas"
                    tooltipPosition="bottom"
                    tooltipZIndex="100000000"></button>
                
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded bg-primary p-1 m-1"
                    (click)="btnAgregarMovimiento()"
                    pTooltip="Agregar un nuevo movimiento"
                    tooltipPosition="bottom"
                    tooltipZIndex="100000000"></button>
           
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-rounded bg-red-700 p-1 m-1"
                    (click)="eliminarMovimientosPorLotes()"
                    pTooltip="Eliminar"
                    tooltipPosition="bottom"
                    tooltipZIndex="100000000"
                    [disabled]="movimientosLotes.length === 0"></button>
            </div>
        </div>
    </div>
</p-toolbar>
</p-card>

<p-card class="pt-2">
    
<p-treeTable
    [value]="arbolDeMovimientos"
    [columns]="cols"
    [style.width]="100"
    [(contextMenuSelection)]="movimientoSeleccionado"
    [contextMenu]="cmfiLA"
    (contextmenu)="onContextMenuSelect(cmfiLA)"
    [reorderableColumns]="true"
    [(selection)]="movimientosLotes"
    sortMode="multiple"
    selectionMode="checkbox"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[5, 10, 15, 20, 30, 50, 100]"
    [styleClass]="sizeTableTree"
    (onNodeSelect)="eventoSeleccionDeMovimiento($event)"
    (onNodeUnselect)="eventoDesSeleccionDeMovimiento($event)"
    (onHeaderCheckboxToggle)="eventoDesSeleccionDeMovimiento($event)"
    currentPageReportTemplate="Mostrando desde el {first} al {last} de {totalRecords} movimientos"
    [showCurrentPageReport]="true"
    [resizableColumns]="true"
    [globalFilterFields]="[
        'cuenta.nombre',
        'fechaCreacion',
        'idComprobante',
        'categoria.nombre',
        'glosa',
        'monto',
        'codComprobante',
        'fechaCreacionCorta'
    ]"
    [filterMode]="'lenient'"
    #tt>
    <ng-template pTemplate="caption">
        <div class="p-fluid p-formgrid grid p-0">
            <div class="field col text-left vertical-align-middle">
                <div class="justify-content-start">
                    <span
                        class="p-input-icon-right"
                        style="width: 300px">
                        <i class="pi pi-search"></i>
                        <input
                            type="text"
                            pInputText
                            class="p-inputtext-sm"
                            size="25"
                            placeholder="Búsqueda global"
                            (input)="
                                tt.filterGlobal($event.target.value, 'contains')
                            "
                            style="width: 300px" />
                    </span>
                </div>
            </div>
            <div class="field col text-right vertical-align-middle">
                <div class="justify-content-end">
                    <span class="text-xs">Seleccionar todos</span>
                    <p-treeTableHeaderCheckbox
                        style="margin-left: 5px"></p-treeTableHeaderCheckbox>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of cols" [ngStyle]="{ display: col.display }" [style.width]="col.width"  class="{{ col.estilo }}">
                <input pInputText type="text" (input)="tt.filter($event.target.value, col.fieldBusqueda, col.filterMatchMode)" class="busquedaCol" placeholder="{{ col.header }}"/>
            </th>
        </tr>
        <tr>
            <th
                class="{{ col.estilo }}"
                *ngFor="let col of columns"
                [style.width]="col.width"
                [ttSortableColumn]="col.fieldBusqueda"
                ttReorderableColumn
                ttResizableColumn
                [ngStyle]="{ display: col.display }">
                {{ col.header }}
                    <p-treeTableSortIcon [field]="col.fieldBusqueda"></p-treeTableSortIcon>
            </th>
        </tr>
       
    </ng-template>
    <ng-template
        pTemplate="body"
        let-rowNode
        let-rowData="rowData"
        let-columns="columns">
        <tr
            [ttContextMenuRow]="rowNode"
            [ttRow]="rowNode"
            [ttSelectableRow]="rowNode"
            [ngClass]="{ filaMarcada: rowNode.node.data.esNueva }"
            (dblclick)="btnVerMovimiento(rowNode.node)"
            class="filatabla"
            >
            <td
                [class]="col.estilo"
                *ngFor="let col of columns; let i = index"
                [ngStyle]="{ display: col.display }">
                <p-treeTableToggler
                    [rowNode]="rowNode"
                    *ngIf="col.agrupa"></p-treeTableToggler>

                <!-- Sólo para el campo comprobante se muestra como un botón donde si tiene codigo se muestra sino el id  -->
                <span (click)="btnVerMovimiento(rowNode.node)"
                        [ngStyle]="{
                            'background-color': rowData.codComprobante ? 'var(--highlight-bg)' : '',
                            'color': rowData.codComprobante ? 'var(--highlight-text-color)' : '',
                            'border-radius': rowData.codComprobante ? 'var(--border-radius)' : '',
                            'padding': rowData.codComprobante ? '0.5rem' : ''
                            }"
                        *ngIf="
                        col.field === 'idComprobante' &&
                            agruparPor !== 'idComprobante';
                        else normal
                    ">
                {{
                    rowData.codComprobante
                        ? '#' + rowData.codComprobante
                        : rowData.idComprobante
                }}   
                </span>

                <!-- Normal no es el cod comprobante ahora solo discriminar si va con tooltip y si se corta el string para mostrar -->
                <ng-template #normal>
                   
                    <span
                        pTooltip="{{ rowData[col.field] }}"
                        *ngIf="col.tooltip; else sintooltip">
                        {{
                            rowData[col.field]
                                ? rowData[col.field].substring(0, 50)
                                : ('' | lineas : col.pipe)
                        }}
                    </span>
                    <ng-template #sintooltip>
                        <ng-container
                            *ngIf="
                                rowData[col.field] &&
                                    rowData[col.field][col.subfield];
                                else normalcontenido
                            ">
                            {{
                                rowData[col.field][col.subfield]
                                    | lineas : col.pipe
                            }}
                        </ng-container>
                        <ng-template #normalcontenido>
                            {{ rowData[col.field] | lineas : col.pipe }}
                        </ng-template>
                    </ng-template>
                </ng-template>
                <!-- Después de la última columna se muestra el checkbox de selección solo cuando la tabla está en modo listado agruparPor = id -->
                <p-treeTableCheckbox
                    [value]="rowNode"
                    *ngIf="i - 1 === col.field.length"
                    style="margin-left: 5px"></p-treeTableCheckbox>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="summary">
        
            <div class="col-6 justify-content-start mt-2">
                <span class="p-float-label">
                    <p-dropdown
                        #ddcuenta
                        [options]="agrupacionesDisponibles"
                        [(ngModel)]="agruparPor"
                        (onChange)="agrupar()"
                        class="text-left"
                        pTooltip="Seleccione una opción para agrupar los resultados"></p-dropdown>
                    <label for="ddcuenta">Agrupar por </label>
                </span>
            </div>
        
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="cols.length">No existe movimientos</td>
        </tr>
    </ng-template>
</p-treeTable>
</p-card>

<p-contextMenu
    #cmfiLA
    [model]="itemsMenuFila"></p-contextMenu>

<app-agregar-movimientos
    (notify)="respuestaAgregarMovimiento($event)"></app-agregar-movimientos>
<app-mover-movimientos
    (notify)="respuestaMoverMovimiento($event)"></app-mover-movimientos>
<app-confirma-eliminar-movimientos
    (notify)="
        respuestaEliminarMovimientos($event)
    "></app-confirma-eliminar-movimientos>


<div class="fijo bg-primary-100 p-4 border-round font-bold text-gray-800" *ngIf="this.sumaLote">

        <span
            
            style="float: right"
            >Suma: {{ this.sumaLote | lineas : 'formatoMoneda' }}</span
        >

</div>    