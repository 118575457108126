<app-menubar *ngIf="configuracionPagina.mostrarMenu"></app-menubar>
<br />
<div class="flex justify-content-center m-2">
    <div class="w-full p-2">
        <p-card styleClass="p-card-shadow">
            <ng-template pTemplate="title">
                {{ configuracionPagina.titulo }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                {{ configuracionPagina.subtitulo }}
            </ng-template>
        </p-card>
        
        <router-outlet></router-outlet>
        
        <p-card styleClass="p-card-shadow" class="p-1">
                <div class="flex justify-content-center m-2">
                    Fecha y hora actual <span style="width: 10px">&nbsp;</span
                    >{{ this.state.hoy | date : 'full' }}
                </div>
        </p-card>
        
    </div>
</div>

<app-mensajes></app-mensajes>

<ng-http-loader></ng-http-loader>

<p-confirmDialog
    header="Confirmaci&oacute;n"
    icon="pi pi-exclamation-triangle"
    [baseZIndex]="100000"></p-confirmDialog>
