import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { AppConfigService } from './app-config.service';

/**
 * Clase encargada de proveer las rutas y métodos para acceder a los endpoints de manera global
 * En caso de algún error en el servicio se comunica directamente con el Error Services
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    /**
     * La url de la api para comunicarse con el back
     */
    API_PATH = this.appConfig.getConfig('apiUrl');

    /**
     * Endpoint que maneja la aplicación
     */
    endpoint = {
        autentificar: '/login',
        cuentasEliminar: '/cuentas/remove',
        cuentasActualizar: '/cuentas/actualizar',
        cuentasCrear: '/cuentas/create',
        cuentastipos: '/cuentas/tipos',
        cuentasPorTipo: '/cuentas/listadoCuentasPorTipo',
        movimientos: '/movimientos',
        movimientosCrear: '/movimientosInsertarpartidaDoble',
        movimientosEditar: '/movimientosEditar',
        movimientosTransferir: '/movimientosTransferir',
        movimientosEditarPartidaDoble: '/movimientosEditarPartidaDoble',
        eliminarPartidaDoble: '/movimientosEliminarPartidaDoble',
        movimientosSaldosCuentas: '/movimientosPorCuenta',
        movimientosSaldoTipo: '/movimientosSaldoTipo',
        movimientosComprobante: '/movimientosPorComprobante',
        comprobanteEliminar: '/movimientosEliminarComprobanteCompleto',
        comprobanteAgregarTag: '/actualizarTagComprobante',
        obtenerCabeceraComprobante: '/obtenerCabeceraComprobante',
        obtenerListadoComprobantes: '/obtenerListadoComprobantes',
        cambiarMovimientoComprobante: '/cambiarMovimientoComprobante',
        obtenerSaldosIniciales: '/obtenerSaldosIniciales',
        detectaAgnosSistema: '/detectaAgnosSistema',
        generarSaldosIniciales: '/generarSaldosIniciales',
        buscarComprobantes: '/buscarComprobantes',
        obtenerSaldosMensuales: '/obtenerSaldosMensuales',
    };

    /**
     * Constructor del servicio
     * @param http  Servicio http para el manejo de las peticiones http
     * @param errorService Servicio para el manejo de errores
     * @param appConfig  Servicio para el manejo de las configuraciones de la aplicación
     */
    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private appConfig: AppConfigService
    ) {
        this.API_PATH = this.appConfig.getConfig('apiUrl');
    }

    /**
     * Método global encargado de realizar las peticiones y setear los encargados de manejar errores
     * @param url url de destino
     * @param body data para enviar en la petición
     */
    post(url, body): Observable<any> {
        const headers = new HttpHeaders();
        url = this.API_PATH + url;

        return this.http
            .post(url, body, {
                headers,
                observe: 'response',
            })
            .pipe(
                tap((res) => res),
                catchError(
                    this.errorService.handleError<any[]>(this.API_PATH, [])
                )
            );
    }

    /**
     * Método global encargado de realizar las peticioNes y setear los encargados de errores
     * @param url de destino
     */
    get(url): Observable<any> {
        url = this.API_PATH + url;

        const headers = new HttpHeaders();
        return this.http.get(url, { headers, observe: 'response' }).pipe(
            tap((res) => res),
            catchError(this.errorService.handleError<any[]>(this.API_PATH, []))
        );
    }
}
