import { Injectable, computed, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, ObservableInput, of } from 'rxjs';
import { ThemeService } from './theme.service';


/**
 * Servicio que gestiona la configuración de la aplicación en archivo json
 */
@Injectable( {
    providedIn: 'root',
} )
export class AppConfigService {
    /**
     * Nombre del tema actual de la aplicación
     */
    temaActual: string;

    private _sizeClass = signal<string>( '' );
    public sizeClass =  computed( () => this._sizeClass() );

    private _sizeClassTree = signal<string>( '' );
    public sizeClassTree =  computed( () => this._sizeClassTree() );

    /**
     * Objecto de configuración
     */
    private config: Object = null;

    /**
     * Constructor del servicio
     * @param httpClient Cliente htt para las peticiones.
     */
    constructor( private httpClient: HttpClient, private theme: ThemeService ) {
        this.config = null;

        // El tema del index.html
        this.temaActual = 'md-dark-indigo';
        const temaPredefinido = JSON.parse( localStorage.getItem( 'themeactual' ) as string );
        const sizeclassTabla = JSON.parse( localStorage.getItem( 'sizeClass' ) as string );
        const sizeclassTree = JSON.parse( localStorage.getItem( 'sizeClassTree' ) as string );
        if ( temaPredefinido && temaPredefinido !== this.temaActual )
        {
            this.changeTheme( temaPredefinido );
        }

        if ( sizeclassTabla )
        {
            this.changeSize( sizeclassTabla );
        }

        if ( sizeclassTree )
        {
            this.changeSizeTree( sizeclassTree );
        }
    }

    /**
     * Establece un nuevo tema según su nombre, lo setea como el tema actual y lo guarda en el localstorage
     * @param theme Nombre del tema que se cambia
     */
    changeTheme( theme: string )
    {
        this.theme.switchTheme( theme );
        this.temaActual = theme;
        localStorage.setItem( 'themeactual', JSON.stringify( theme ) );
    }

    changeSize ( clase: string )
    {
        this._sizeClass.set( clase );
        localStorage.setItem( 'sizeClass', JSON.stringify( clase ) );
    }

    changeSizeTree ( clase: string )
    {
        this._sizeClassTree.set( clase );
        localStorage.setItem( 'sizeClassTree', JSON.stringify( clase ) );
    }

    /**
     * Obtiene una determinada clave que existe en el archivo de configuración
     * @param key Clave a obtener
     * @returns retorna el valor de la clave
     */
    public getConfig( key: any ) {
        return this.config[key];
    }

    /**
     * LLama al objeto que posee las configuraciones
     */
    public load(): Promise<boolean> {
        let url = environment.rutaArchivoConf;

        return new Promise<boolean>( ( resolve: ( a: boolean ) => void ): void => {
            this.httpClient
                .get( url )
                .pipe(
                    map( ( x ) => {
                        this.config = x;
                        resolve( true );
                    } ),
                    catchError(
                        (
                            x: { status: number },
                            caught: Observable<void>
                        ): ObservableInput<{}> => {
                            if ( x.status !== 404 ) {
                                resolve( false );
                            }
                            return of( {} );
                        }
                    )
                )
                .subscribe();
        } );
    }
}
