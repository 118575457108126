<p-menubar
    [model]="items"
    #menubar>
    <img
        src="assets/logo.png"
        alt="Account - Cuentas personales"
        title="Account - Cuentas personales"
        srcset=""
        height="50px" />
        <p-button type="button" class="mr-2"  [rounded]="true" [text]="true" severity="warning " (click)="sidebarVisible = true" 
        icon="pi pi-chevron-left
        "></p-button>
</p-menubar>

<app-buscar-comprobante></app-buscar-comprobante>



<app-agregar-movimientos
    (notify)="respuestaAgregarMovimiento($event)"
    #agregarMovimiento></app-agregar-movimientos>


<p-sidebar [(visible)]="sidebarVisible" position="right">
    
    <h2>Preferencias</h2>
    <p-divider></p-divider>
    <h3>Temas</h3>

    <div class="flex align-items-center justify-content-between gap-3 mb-3">
        <button 
            (click)="this.appConfig.changeTheme( 'arya-blue' );"
            pTooltip="Arya blue"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border" 
            style="border-radius: 30px;"
            >
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #121212 0%, #2a2a2a 100%);" >
                {{ this.appConfig.temaActual === 'arya-blue' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'bootstrap4-dark-purple' );"
            pTooltip="Bootstrap4 dark purple"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border" 
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #20262e 0%, #2a323d 100%);">
                {{ this.appConfig.temaActual === 'bootstrap4-dark-purple' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'bootstrap4-light-purple' );"
            pTooltip="Bootstrap4 light purple"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border" 
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #522468 0%, #ffffff 100%);">
                {{ this.appConfig.temaActual === 'bootstrap4-light-purple' ? '___' : '' }}
            </span>
        </button>
    </div>
    <div class="flex align-items-center justify-content-between gap-3 mb-3">
        <button 
            (click)="this.appConfig.changeTheme( 'lara-dark-teal' );"
            pTooltip="Dark teal"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border"
            style="border-radius: 30px;" >
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #111827 0%, #1f2937 100%);">
                {{ this.appConfig.temaActual === 'lara-dark-teal' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'md-dark-indigo' );"
            pTooltip="Dark indigo"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border"
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #0c0c0c 0%, #141414 100%);">
                {{ this.appConfig.temaActual === 'md-dark-indigo' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'md-light-indigo' );"
            pTooltip="Light indigo"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border"
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #3f51b5 0%, #ffffff 100%);">
                {{ this.appConfig.temaActual === 'md-light-indigo' ? '___' : '' }}
            </span>
        </button>
    </div>
    <div class="flex align-items-center justify-content-between gap-3 mb-3">
        <button 
            (click)="this.appConfig.changeTheme( 'saga-blue' );"
            pTooltip="Saga blue"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border"
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #2196f3 0%, #ffffff 100%);">
                {{ this.appConfig.temaActual === 'saga-blue' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'saga-green' );"
            pTooltip="Saga green"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border" 
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #4caf50 0%, #ffffff 100%);">
                {{ this.appConfig.temaActual === 'saga-green' ? '___' : '' }}
            </span>
        </button>
        <button 
            (click)="this.appConfig.changeTheme( 'viva-dark' );"
            pTooltip="Viva Dark"
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 hover:border-500 surface-border" 
            style="border-radius: 30px;">
            <span class="block h-1rem w-full" style="border-radius: 30px; background: linear-gradient(180deg, #0e1315 0%, #161d21 100%);">
                {{ this.appConfig.temaActual === 'viva-dark' ? '___' : '' }}
            </span>
        </button>
    </div>
    <p-divider></p-divider>
    <h3>Tamaño Tablas</h3>

    <p-button 
        (click)="this.appConfig.changeSize( 'p-datatable-sm' );this.appConfig.changeSizeTree( 'p-treetable-sm' );"
        icon="pi pi-table" [rounded]="true" [text]="true" [raised]="true" severity="warning " size="small">
    </p-button>
    <p-button 
    (click)="this.appConfig.changeSize( '' );this.appConfig.changeSizeTree( '' )"
        icon="pi pi-table" [rounded]="true" [text]="true" [raised]="true" severity="warning " >
    </p-button>
    <p-button 
    (click)="this.appConfig.changeSize( 'p-datatable-lg' );this.appConfig.changeSizeTree( 'p-treetable-lg' );"
        icon="pi pi-table" [rounded]="true" [text]="true" [raised]="true" severity="warning " size="large">
    </p-button>


</p-sidebar>
    