import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

@Component({
    selector: 'app-grafico-categoria',
    templateUrl: './grafico-categoria.component.html',
    styleUrls: ['./grafico-categoria.component.less'],
})
export class GraficoCategoriaComponent implements OnInit {
    /**
     * Datos para el gráfico
     */
    dataPie: any;
    /**
     * Opciones del gráfico
     */
    chartOptions: any;

    constructor(
        private auth: AuthenticationService,
        private http: HttpService,
        private state: StateService
    ) {}

    ngOnInit(): void {
        this.obtenerSaldosMovimientosCategorias();
    }

    /**
     * Obtener los movimientos sumados y agrupados para las categorías
     */
    obtenerSaldosMovimientosCategorias() {
        const objEnviar = {
            tipo: 'CAT',
            usuario: this.auth.user.codUsuario,
            fechaDesde: this.state.inicioAño,
            fechaHasta: this.state.finAño,
        };

        this.http
            .post(this.http.endpoint.movimientosSaldosCuentas, objEnviar)
            .subscribe((res) => {
                if (res.status === 200) {
                    const label = [];
                    const datos = [];
                    const color = [];
                    res.body.datos.forEach((sld) => {
                        label.push(sld.ctaNombre);
                        datos.push(sld.saldo * -1);
                        color.push(sld.color);
                    });

                    this.dataPie = {
                        backgroundColor: color,
                        labels: label,
                        datasets: [{ data: datos, backgroundColor: color }],
                    };

                    this.chartOptions = {
                        plugins: {
                            legend: {
                                labels: { color: '#ffffff' },
                                position: 'bottom',
                            },
                            title: {
                                display: true,
                                text: `Catgeorias`,
                            },
                        },
                    };
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en obtenerSaldosMovimientosCuentas',
                        res.status + ' : ' + res.statusText
                    );
                }
            });
    }
}
