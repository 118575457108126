import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BuscarComprobanteComponent } from '../comprobantes/buscar-comprobante/buscar-comprobante.component';
import { AgregarMovimientosComponent } from '../movimientos/agregar-movimientos/agregar-movimientos.component';
import { AuthenticationService } from '../servicios/authentication.service';
import { AppConfigService } from '../servicios/app-config.service';

/**
 * Componente que genera el menú que se muestra en el sistema
 */
@Component( {
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.less'],
} )
export class MenubarComponent implements OnInit {
    /**
     * Indica si el panel latearl se muestra
     */
    sidebarVisible: boolean = false;
    /**
     * Elementos del menú principal
     */
    items: MenuItem[];
    /**
     * Widget de buscar comprobante para enlace desde el menú
     */
    @ViewChild( BuscarComprobanteComponent )
        buscarComprobante: BuscarComprobanteComponent;
    /**
     * Widget de agregar movimiento para enlace desde el menú
     */
    @ViewChild( AgregarMovimientosComponent )
        agregarMovimiento: AgregarMovimientosComponent;
    /**
     * Servicio de configuración
     */
    appConfig = inject( AppConfigService );

    constructor( private auth: AuthenticationService ) {}

    ngOnInit() {
        this.items = [
            {
                label: 'Inicio',
                icon: 'pi pi-fw pi-home',
                routerLink: ['tablero'],
            },
            {
                label: 'Movimientos',
                icon: 'pi pi-fw pi-money-bill',
                items: [
                    {
                        label: 'Listado',
                        icon: 'pi pi-list',
                        routerLink: ['movimientos'],
                    },
                    {
                        label: 'Saldos Iniciales',
                        icon: 'pi pi-ticket',
                        routerLink: ['saldosiniciales'],
                    },
                    {
                        label: 'Nuevo Movimiento',
                        icon: 'pi pi-plus',
                        command: () => this.agregarMovimiento.mostrarAgregarMovimiento(),
                    },
                ],
            },
            {
                label: 'Comprobantes',
                icon: 'pi pi-book',
                command: () => this.buscarComprobante.mostrarComponente(),
            },
            {
                label: 'Informes',
                icon: 'pi pi-fw pi-chart-bar',
                items: [
                    {
                        label: 'Mensual',
                        icon: 'pi pi-chart-line',
                        routerLink: ['informeMensual'],
                    },
                    {
                        label: 'Diario',
                        icon: 'pi pi-chart-bar',
                        routerLink: ['graficoSaldosLineal/true'],
                    },
                    {
                        label: 'Categorias',
                        icon: 'pi pi-chart-pie',
                        routerLink: ['graficoCategoria'],
                    },
                ],
            },
            {
                label: 'Cuentas',
                icon: 'pi pi-briefcase',
                routerLink: ['cuentas'],
            },
            {
                label: 'Salir',
                icon: 'pi pi-fw pi-power-off',
                command: () => this.auth.logout(),
            },
        ];
    }

    /**
   * Método que se ejecutar luego de agregar un movimiento
   * @param objRes Objeto respuesta
   */
    respuestaAgregarMovimiento( objRes: { res: string } ) {
        if ( objRes.res === 'OK' ) {
            /* this.saldoCta.obtenerSaldosMovimientosCuentas();
            this.saldoCat.obtenerSaldosMovimientosCuentas();
            this.grafico.obtenerSaldosMovimientosCuentas();*/
        }
    }
}
