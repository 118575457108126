import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Comprobante } from 'src/app/interfaces/comprobante';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

@Component({
    selector: 'app-buscar-comprobante',
    templateUrl: './buscar-comprobante.component.html',
    styleUrls: ['./buscar-comprobante.component.less'],
})

/**
 * Componente que permite buscar comprobantes por año
 */
export class BuscarComprobanteComponent implements OnInit {
    /**
     * Controla si se muestra o no el comprobante
     */
    seMuestraBuscarComprobante = false;

    listadoAgnos: number[];
    agnoSeleccionado: number;
    listadoComprobantes: Comprobante[];
    comprobanteSeleccionado: Comprobante;

    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.state.listadoAgnosDisponibles$.subscribe((agnos) => {
            this.listadoAgnos = agnos;
        });
    }

    /**
     * Obtiene el listado de comprobantes del año actual para que el usuario elija donde mover el movimiento
     * @returns La llamada al endpoint que obtiene los comprobantes
     */
    obtenerListadoComprobantes() {
        const obConsulta = {
            codUsuario: this.auth.user.codUsuario,
            agnoActual: +this.agnoSeleccionado,
        };

        this.http
            .post(this.http.endpoint.obtenerListadoComprobantes, obConsulta)
            .subscribe((res) => {
                if (res.status === 200) {
                    this.listadoComprobantes = res.body.datos;
                }
            });
    }
    /**
     * Muestra el componente y limpia los campos
     */
    mostrarComponente() {
        this.comprobanteSeleccionado = {} as Comprobante;
        // this.listadoComprobantes = [] ;
        this.seMuestraBuscarComprobante = true;
    }

    /**
     * Evento que muestra el comprobante seleccionado
     */
    btnVerComprobante() {
        this.seMuestraBuscarComprobante = false;
        this.router
            .navigate(
                ['/comprobante', { id: this.comprobanteSeleccionado.id }],
                { relativeTo: null }
            )
            .then(() => {
                window.location.reload();
            });
    }
}
