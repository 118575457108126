import { Component, Input, OnInit, ViewEncapsulation, effect } from '@angular/core';
import { AppConfigService } from 'src/app/servicios/app-config.service';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';

/**
 * Componente que muestra la tabla de los saldos
 */
@Component( {
    selector: 'app-saldos',
    templateUrl: './saldos.component.html',
    styleUrls: ['./saldos.component.less'],
    encapsulation: ViewEncapsulation.None,
} )
export class SaldosComponent implements OnInit {
    saldos: any[];
    saldosTodos: any[];
    @Input() tipo: string;
    @Input() orden: string;
    @Input() invertir;
    saldoTotal: number;
    mostrarEnCero = false;
    /**
     * Tamaño de la tabla para la visualización
     */
    sizeTable: string;

    constructor(
        private http: HttpService,
        private state: StateService,
        private auth: AuthenticationService,
        public appConfig: AppConfigService
    ) {
        this.sizeTable = this.appConfig.sizeClass();
    }

    ngOnInit(): void {
        this.obtenerSaldosMovimientosCuentas();
    }

    /**
     * Obtener los movimients sumados y agrupados por id nombre cuenta para el tipo solicitado
     * adicionalmente suma los resultados y ordena según el orden solicitado
     */
    obtenerSaldosMovimientosCuentas() {
        const objEnviar = {
            tipo: this.tipo,
            usuario: this.auth.user.codUsuario,
            fechaDesde: this.state.inicioAño,
            fechaHasta: this.state.finAño
        };

        this.http
            .post( this.http.endpoint.movimientosSaldosCuentas, objEnviar )
            .subscribe( ( res ) => {
                if ( res.status === 200 ) {
                    this.saldosTodos = res.body.datos;
                    this.saldos = res.body.datos;
                    this.saldoTotal = 0;

                    this.saldos.forEach( ( cta ) => {
                        this.invertir
                            ? ( cta.saldo = + cta.saldo * - 1 )
                            : ( cta.saldo = + cta.saldo );
                        this.saldoTotal += + cta.saldo;
                    } );

                    if ( this.orden === 'asc' ) {
                        this.saldos = this.saldos.sort( ( a, b ) => parseFloat( a.saldo ) - parseFloat( b.saldo ) );
                    } else {
                        this.saldos = this.saldos.sort( ( a, b ) => parseFloat( b.saldo ) - parseFloat( a.saldo ) );
                    }

                    // Filtrar los objetos con saldo distinto de 0
                    if ( ! this.mostrarEnCero )
                    {
                        this.saldos = this.saldos.filter( ( saldo ) => {
                            return saldo.saldo != '0';
                        } );
                    }


                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en obtenerSaldosMovimientosCuentas',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    actualizaMostrarSaldosEnCero( event )
    {

        // Filtrar los objetos con saldo distinto de 0
        if ( ! this.mostrarEnCero )
        {
            this.saldos = this.saldos.filter( ( saldo ) => {
                return saldo.saldo != '0';
            } );
        }
        else
        {
            this.saldos = this.saldosTodos;
        }
    }

    /**
     * El efecto se dispara cada vez que una señal cambia
    */
    public sizetableChangedEffect = effect( () => {

        this.sizeTable = this.appConfig.sizeClass();
    } );
}
