<p-card class="p-1">
    <p-toolbar *ngIf="this.comprobante">
        <div class="p-toolbar-group-start">
            <p-button
                *ngIf="this.route.snapshot.params.id"
                [routerLink]="['/movimientos']"
                icon="pi pi-arrow-left"
                type="button"
                label="Volver a Listado de Movimientos"
                styleClass="p-button-text"></p-button>
        </div>
        <div class="p-toolbar-group-end">
            <div class="flex flex-column ms:flex-row">
                <div>
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-arrows-h"
                        class="p-button-rounded p-button-success p-1 m-1"
                        (click)="btnAgregarTransferencia()"
                        pTooltip="Agregar transferencia entre cuentas"
                        tooltipPosition="bottom"
                        tooltipZIndex="100000000"></button>
            
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-plus"
                        class="p-button-rounded p-button-success p-1 m-1"
                        (click)="btnAgregarMovimiento()"
                        pTooltip="Agrega movimiento"
                        tooltipZIndex="100000000"></button>
                
                    <button
                        pButton
                        pRipple
                        type="button"
                        (click)="guardarCambios()"
                        pTooltip="Grabar cambios"
                        tooltipZIndex="100000000"
                        icon="pi pi-save"
                        class="p-button-rounded p-button-success p-1 m-1"
                        [disabled]="!this.puedeGrabarComprobante"></button>
                
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger  p-1 m-1"
                        (click)="btnEliminar()"
                        pTooltip="Eliminar"
                        tooltipPosition="bottom"
                        tooltipZIndex="100000000"
                        [disabled]="movimientosLotes.length === 0"></button>
                </div>
            </div>
        </div>
    </p-toolbar>
    
</p-card>
<p-tabView (onChange)="cambiarVista($event.index)">
    <p-tabPanel header="Consolidado">
        <p-card class="pt-2 w-full">
            <p-table
                [value]="this.comprobante.lineas"
                *ngIf="this.panelActivo === 0 && this.comprobante.lineas"
                [(selection)]="movimientosLotes"
                (onRowSelect)="totalizarSeleccionados()"
                (onRowUnselect)="totalizarSeleccionados()"
                [resizableColumns]="true"
                currentPageReportTemplate="Mostrando desde el {first} al {last} de {totalRecords} movimientos"
                [paginator]="true"
                [rows]="rows"
                [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
                currentPageReportTemplate="Mostrando desde el {first} al {last} de {totalRecords} movimientos"
                [showCurrentPageReport]="true"
                [styleClass]="sizeTable"
                [globalFilterFields]="[
                'cuenta.nombre',
                'fechaCreacion',
                'idComprobante',
                'categoria.nombre',
                'glosa',
                'monto',
                'codComprobante',
                'fechaCreacionCorta'
                ]"
                #consolidado>
                <ng-template pTemplate="caption">
                    <div class="grid" *ngIf="this.comprobante" >
                        <div class="col  vertical-align-top" >
                            
                                Comprobante: {{ this.comprobante.id }}
                            
                                <p-inplace
                                    closable="closable"
                                    (onActivate)="cambiarTagComprobante()"
                                    closeIcon="pi pi-check"
                                    (onDeactivate)="guardarTagComprobante()">
                                    <ng-template pTemplate="display">
                                        <p-tag
                                            value="{{ this.comprobante.codComprobante }}"
                                            icon="pi pi-info-circle"
                                            severity="info"
                                            pTooltip="Editar código de comprobante"></p-tag>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <input
                                            type="text"
                                            [(ngModel)]="this.comprobante.codComprobante"
                                            pInputText
                                            class="p-inputtext-sm" />
                                    </ng-template>
                                 </p-inplace>
                                
                        </div>
                        <div class="col">
                            <div class="justify-content-start">
                                <span
                                    class="p-input-icon-right"
                                    style="width: 300px">
                                    <i class="pi pi-search"></i>
                                    <input
                                        type="text"
                                        pInputText
                                        class="p-inputtext-sm"
                                        size="25"
                                        placeholder="Búsqueda global"
                                        (input)="
                                            consolidado.filterGlobal($event.target.value, 'contains')
                                        "
                                        style="width: 300px" />
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="text-right p-2">
                                <span class="text-xs mt-0">Seleccionar todos</span>
                                <p-tableHeaderCheckbox
                                class="ml-1 mr-0 mt-0"
                                (click)="totalizarSeleccionados()"
                                ></p-tableHeaderCheckbox>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th
                            class="priority-3"
                            pResizableColumn
                            pSortableColumn="fechaCreacion">
                            Fecha<p-sortIcon field="fechaCreacion"></p-sortIcon>
                        </th>
                        <th
                            
                            pResizableColumn
                            pSortableColumn="cuenta.nombre">
                            Cuenta<p-sortIcon field="cuenta.nombre"></p-sortIcon>
                        </th>
                        <th
                            class="priority-2"
                            pResizableColumn
                            pSortableColumn="categoria.nombre">
                            Categoría<p-sortIcon
                                field="categoria.nombre"></p-sortIcon>
                        </th>
                        <th
                            class="priority-2"
                            pResizableColumn
                            pSortableColumn="glosa">
                            Glosa<p-sortIcon field="glosa"></p-sortIcon>
                        </th>
                        <th pSortableColumn="monto"
                            class="text-right">
                            Monto<p-sortIcon field="monto"></p-sortIcon>
                        </th>
                        <th style="width: 2rem">
                           &nbsp;
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-mov>
                    <tr class="filatabla"
                        [pSelectableRow]="mov"
                        [ngClass]="{ LineaNueva: mov.esNueva || mov.esEditada }"
                        (dblclick)="mostrarMovimiento(mov)">
                        <td class="priority-3">
                            {{ mov.fechaCreacion | lineas : 'formatoFecha' }}
                        </td>
                        <td >{{ mov.cuenta.nombre }}</td>
                        <td class="priority-2">{{ mov.categoria.nombre }}</td>
                        <td class="priority-2">{{ mov.glosa }}</td>
                        <td class="text-right">
                            
                            <span (click)="mostrarMovimiento(mov)"
                                >
                                {{ mov.monto | lineas : 'formatoMoneda' }}
                            </span>
                            
                        </td>
                        <td>
                            <p-tableCheckbox [value]="mov"
                            ></p-tableCheckbox>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="flex justify-content-between flex-wrap">
                    
                        <button
                        pButton
                        pRipple
                        tooltipZIndex="100000000"
                        class="p-button-text p-button-danger"
                        icon="pi pi-trash"
                        type="button"
                        label="Eliminar comprobante"
                        (click)="btnEliminarComprobante()"
                        pTooltip="Eliminar comprobante completo"></button>
                    
                    
                        <p class="text-xs text-right">
                            Fecha creación {{ this.comprobante.fechaCreacion | date : 'full' }}
                        </p>
                    </div>
                </ng-template>
            </p-table>
        </p-card>
    </p-tabPanel>

    <p-tabPanel header="Contable">
        <p-card class="pt-2 w-full">
            <p-table
                *ngIf="this.panelActivo === 1"
                [value]="this.comprobante.lineas"
                dataKey="id"
                (onEditInit)="onEditInit($event)"
                (onEditComplete)="onEditComplete($event)"
                (onEditCancel)="onEditCancel($event)"
                [responsive]="true"
                [(selection)]="movimientosLotes"
                selectionMode="checkbox"
                (onRowSelect)="totalizarSeleccionados()"
                (onRowUnselect)="totalizarSeleccionados()"
                [paginator]="true"
                [rows]="rows"
                [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
                currentPageReportTemplate="Mostrando desde el {first} al {last} de {totalRecords} movimientos"
                [showCurrentPageReport]="true"
                [resizableColumns]="true"
                [styleClass]="sizeTable"
                [globalFilterFields]="[
                'cuenta.nombre',
                'fechaCreacion',
                'idComprobante',
                'categoria.nombre',
                'glosa',
                'monto',
                'codComprobante',
                'fechaCreacionCorta'
                ]"
                #tbl>
                <ng-template pTemplate="caption">
                    <div class="grid" *ngIf="this.comprobante" >
                        <div class="col  vertical-align-top" >
                            
                                Comprobante: {{ this.comprobante.id }}
                            
                                <p-inplace
                                    closable="closable"
                                    (onActivate)="cambiarTagComprobante()"
                                    closeIcon="pi pi-check"
                                    (onDeactivate)="guardarTagComprobante()">
                                    <ng-template pTemplate="display">
                                        <p-tag
                                            value="{{ this.comprobante.codComprobante }}"
                                            icon="pi pi-info-circle"
                                            severity="info"
                                            pTooltip="Editar código de comprobante"></p-tag>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <input
                                            type="text"
                                            [(ngModel)]="this.comprobante.codComprobante"
                                            pInputText
                                            class="p-inputtext-sm" />
                                    </ng-template>
                                 </p-inplace>
                                
                        </div>
                        <div class="col">
                            <div class="justify-content-start">
                                <span
                                    class="p-input-icon-right"
                                    style="width: 300px">
                                    <i class="pi pi-search"></i>
                                    <input
                                        type="text"
                                        pInputText
                                        class="p-inputtext-sm"
                                        size="25"
                                        placeholder="Búsqueda global"
                                        (input)="
                                            tbl.filterGlobal($event.target.value, 'contains')
                                        "
                                        style="width: 300px" />
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="text-right p-2">
                                <span class="text-xs mt-0">Seleccionar todos</span>
                                <p-tableHeaderCheckbox
                                class="ml-1 mr-0 mt-0"
                                (click)="totalizarSeleccionados()"
                                ></p-tableHeaderCheckbox>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th
                            style="width: 8%"
                            pResizableColumn
                            pSortableColumn="orden"
                            >
                            Orden <p-sortIcon field="orden"></p-sortIcon>
                        </th>
                        <th
                            style="width: 5%"
                            pResizableColumn
                            pSortableColumn="id"
                            class="p-text-center">
                            id <p-sortIcon field="id"></p-sortIcon>
                        </th>
                        <th
                            style="width: 10%"
                            pResizableColumn
                            pSortableColumn="fecha"
                            >
                            Fecha <p-sortIcon field="fecha"></p-sortIcon>
                        </th>
                        <th
                            style="width: 20%"
                            pResizableColumn
                            pSortableColumn="cuenta.nombre"
                            >
                            Cuenta <p-sortIcon field="cuenta.nombre"></p-sortIcon>
                        </th>
                        <th
                            style="width: 20%"
                            pResizableColumn
                            pSortableColumn="glosa"
                            >
                            Glosa <p-sortIcon field="glosa"></p-sortIcon>
                        </th>
                        <th
                            style="width: 15%"
                            pResizableColumn
                            pSortableColumn="monto"
                            >
                            Debe<p-sortIcon field="monto"></p-sortIcon>
                        </th>
                        <th
                            style="width: 15%"
                            pResizableColumn
                            pSortableColumn="monto"
                            >
                            Haber<p-sortIcon field="monto"></p-sortIcon>
                        </th>

                        <th style="width: 2rem">
                            &nbsp;
                         </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-lineasComprobante
                    let-rowIndex="rowIndex"
                    let-last="last">
                    <tr
                        class="filatabla"
                        [ngClass]="{
                            LineaNueva:
                                lineasComprobante.esNueva ||
                                lineasComprobante.esEditada
                        }">
                        <td class="p-2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <span
                                        *ngIf="
                                            lineasComprobante.esNueva ||
                                            lineasComprobante.esEditada
                                        "
                                        >*</span
                                    >
                                    <input
                                        pInputText
                                        type="text"
                                        [(ngModel)]="lineasComprobante.orden"
                                        required />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ lineasComprobante.orden }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="p-2 p-text-center">
                            <span pTooltip="{{ lineasComprobante.idMovRef }}">
                                {{ lineasComprobante.id }}
                            </span>
                        </td>
                        <td class="p-2">
                            {{
                                lineasComprobante.fechaCreacion
                                    | lineas : 'formatoFecha'
                            }}
                        </td>
                        <td
                            [pEditableColumn]="lineasComprobante"
                            [pEditableColumnField]="'cuenta'"
                            [pEditableColumnRowIndex]="rowIndex"
                            class="p-2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown
                                        appendTo="body"
                                        #ddcuenta
                                        [options]="cuentaOcategoria"
                                        optionLabel="nombre"
                                        [(ngModel)]="lineasComprobante.cuenta"
                                        [style]="{ width: '98%' }"
                                        class="p-inputtext-sm"
                                        placeholder="Seleccione una cuenta"></p-dropdown> </ng-template
                                >|
                                <ng-template pTemplate="output">
                                    <span *ngIf="lineasComprobante.idMovRef"
                                        >-</span
                                    >
                                    {{ lineasComprobante.cuenta.nombre }}
                                </ng-template>
                            </p-cellEditor>
                        </td>

                        <td
                            [pEditableColumn]="lineasComprobante"
                            [pEditableColumnField]="'glosa'"
                            [pEditableColumnRowIndex]="rowIndex"
                            class="p-2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input
                                        pInputText
                                        type="text"
                                        [(ngModel)]="lineasComprobante.glosa"
                                        style="width: 90%"
                                        class="p-inputtext-sm"
                                        required />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span pTooltip="{{ lineasComprobante.glosa }}">
                                        {{
                                            lineasComprobante.glosa.substring(0, 50)
                                        }}
                                    </span>
                                </ng-template>
                            </p-cellEditor>
                        </td>

                        <td
                            [pEditableColumn]="lineasComprobante"
                            [pEditableColumnField]="'montoDebe'"
                            [pEditableColumnRowIndex]="rowIndex"
                            class="p-2 text-left">
                            <p-cellEditor *ngIf="+lineasComprobante.monto > 0">
                                <ng-template pTemplate="input">
                                    <input
                                        pInputText
                                        type="text"
                                        [(ngModel)]="lineasComprobante.monto"
                                        style="width: 90%"
                                        class="p-inputtext-sm" />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span *ngIf="+lineasComprobante.monto > 0">{{
                                        lineasComprobante.montoDebe
                                            | lineas : 'formatoMoneda'
                                    }}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td
                            [pEditableColumn]="lineasComprobante"
                            [pEditableColumnField]="'montoHaber'"
                            [pEditableColumnRowIndex]="rowIndex"
                            class="p-2 text-left">
                            <p-cellEditor *ngIf="+lineasComprobante.monto < 0">
                                <ng-template pTemplate="input">
                                    <input
                                        pInputText
                                        type="text"
                                        [(ngModel)]="lineasComprobante.monto"
                                        style="width: 90%"
                                        class="p-inputtext-sm" />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span *ngIf="+lineasComprobante.monto < 0">{{
                                        lineasComprobante.montoHaber
                                            | lineas : 'formatoMoneda'
                                    }}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="p-2 p-text-center">
                            <p-tableCheckbox
                                [value]="lineasComprobante"></p-tableCheckbox>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="grid">
                        <div class="col-5 text-right">
                            Debe<br /><span *ngIf="this.sumaDebe">{{
                                this.sumaDebe | lineas : 'formatoMoneda'
                            }}</span>
                        </div>
                        <div class="col-2 p-text-center">
                            <p-divider layout="vertical"></p-divider>
                        </div>
                        <div class="col-5 text-left">
                            Haber<br /><span *ngIf="this.sumaHaber">
                                {{
                                    this.sumaHaber | lineas : 'formatoMoneda'
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="flex justify-content-between flex-wrap">
                    
                        <button
                        pButton
                        pRipple
                        tooltipZIndex="100000000"
                        class="p-button-text p-button-danger"
                        icon="pi pi-trash"
                        type="button"
                        label="Eliminar comprobante"
                        (click)="btnEliminarComprobante()"
                        pTooltip="Eliminar comprobante completo"></button>
                    
                    
                        <p class="text-xs text-right">
                            Fecha {{ this.comprobante.fechaCreacion | date : 'full' }}
                        </p>
                    </div>
                </ng-template>
            </p-table>
        </p-card>
    </p-tabPanel>
</p-tabView>



<app-agregar-movimientos
    (notify)="cierraAgregarMovimiento($event)"></app-agregar-movimientos>
<app-confirma-eliminar-movimientos
    (notify)="
        respuestaEliminarMovimientos($event)
    "></app-confirma-eliminar-movimientos>


<div class="fijo bg-primary-100 p-4 border-round font-bold text-gray-800" *ngIf="this.sumaMonto">

    <span
        
        style="float: right"
        >Suma: {{ this.sumaMonto | lineas : 'formatoMoneda' }}</span
    >

</div>    