<p-card>
<form [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="flex justify-content-center">        
    <div class="surface-card p-4 shadow-2 border-round w-2 lg:w-2 " >
        <div class="text-center mb-5">
            <img src="assets/logo.png"
            alt="Account - Cuentas personales" height="50" class="mb-3">
            <div class="text-900 text-3xl font-medium mb-3">Bienvenido</div>
        </div>

        <div>
            <label for="username" class="block text-900 font-medium mb-2">Usuario</label>
            <input  name="username"
            formControlName="username" pInputText class="w-full mb-3">

            <label for="password" class="block text-900 font-medium mb-2">Password</label>
            <input formControlName="password" name="password" type="password" placeholder="Password" pInputText class="w-full mb-3">


            <button pButton pRipple label="Entrar" icon="pi pi-user" class="w-full"  type="submit" [disabled]="!formulario.valid"></button>
        </div>
    </div>
</div>
    
</form>
</p-card>