import { Validator, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { Directive, OnInit, forwardRef } from '@angular/core';

/**
 * Directiva de validación para la glosa de un comprobante
 */
@Directive( {
    selector: '[appValidaInputs]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ValidaGlosaValidator,
            multi: true,
        },
    ],
} )
export class ValidaGlosaValidator implements Validator, OnInit {
    /**
     * OnInit vacío
     */
    ngOnInit() {}

    /**
     * Evento de validación que controla el contenido de la glosa
     * @param c El control de formulario que contiene la glosa
     * @returns Retorna null o el error
     */
    validate( c: UntypedFormControl ) {
        // -- debe ser una glosa con valor
        if ( c.value && c.value.trim().length === 0 ) {
            return { error: true };
        }
        return null;
    }
}
