import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StateService } from '../servicios/state.service';

@Component({
    selector: 'app-mensajes',
    templateUrl: './mensajes.component.html',
    styleUrls: ['./mensajes.component.less'],
})

/**
 * Clase Componente encargada de mostrar el toast con mensajes para el usuario
 * los mensajes se leen desde el servicio de State del objMensaje
 */
export class MensajesComponent implements OnInit {
    public msj = [];
    constructor(
        private messageService: MessageService,
        private state: StateService
    ) {
        this.state.objMensaje$.subscribe((data) => {
            this.messageService.clear();
            this.mostrarMensaje(data.tipo, data.modulo, data.mensaje);
        });
    }

    ngOnInit(): void {}

    /**
     * muestra el mensaje seteado
     */
    mostrarMensaje(tipo: string, modulo: string, mensaje: string) {
        this.messageService.add({
            severity: tipo,
            summary: modulo,
            detail: mensaje,
            sticky: true,
        });
    }
}
