import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { StateService } from 'src/app/servicios/state.service';
import { HttpService } from 'src/app/servicios/http.service';
import { LineaComprobante } from 'src/app/interfaces/linea-comprobante';
import { Comprobante } from 'src/app/interfaces/comprobante';
import { LineasPipe } from 'src/app/pipes/lineas.pipe';
import { Cuenta } from 'src/app/interfaces/cuenta';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { MoverMovimientosComponent } from '../mover-movimientos/mover-movimientos.component';
import { AuthenticationService } from 'src/app/servicios/authentication.service';

/**
 * Componente que permite agregar movimietos y transferencias
 */
@Component( {
    selector: 'app-agregar-movimientos',
    templateUrl: './agregar-movimientos.component.html',
    styleUrls: ['./agregar-movimientos.component.less'],
    encapsulation: ViewEncapsulation.None,
} )
export class AgregarMovimientosComponent implements OnInit {
    /**
     * Comprobante que permite mover movimiento entre comprobantes
     */
    @ViewChild( MoverMovimientosComponent )
        moverComprobante: MoverMovimientosComponent;
    /**
     * Evento de emisión para comunicarse con los componente 'padre'
     */
    @Output() notify: EventEmitter<{}> = new EventEmitter<{}>();
    /**
     * Comprobante al que se le está agregando el movimiento
     */
    comprobante: Comprobante;
    /**
     * Grupo de campos del formulario
     */
    formMov: UntypedFormGroup;
    /**
     * Lista de cuentas disponibles
     */
    listadoCuentas: Cuenta[];
    /**
     * Lista de categorías disponibles
     */
    listadoCategorias: Cuenta[];
    /**
     * Posición del componente
     */
    position = 'top';
    /**
     * Pipe para el manejo de las líneas
     */
    lineasPipe: LineasPipe;
    /**
     * Objeto que controla las opciones del comprobante
     */
    menuOpcionesComprobante: any;
    /**
     * Titulo del modal
     */
    tituloDinamico: string;
    /**
     * Indica si se esta agregando un movimiento
     */
    estaAgregando = false;
    /**
     * Indica si es una transferencia
     */
    esTransferencia = false;
    /**
     * Indica si se muestra el componente
     */
    seMuestraAgregarMovimiento = false;

    /**
     * El Constructor inicializa el formulario
     * @param formBuilder Utilziado para el manejo del formulario
     * @param http  Servicio utilizado para las peticiones http
     * @param state Servicio utilizado para el estado de la aplicación
     * @param confirma Servicio de confirmación de usuario
     * @param router Utilizado para la navegación
     */
    constructor(
        private formBuilder: UntypedFormBuilder,
        private http: HttpService,
        private state: StateService,
        private confirma: ConfirmationService,
        private router: Router,
        private auth: AuthenticationService
    ) {
        this.inicializaFormulario();
    }

    /**
     * OnInit vacío
     */
    ngOnInit(): void {}

    /**
     * Seteo del formulario a sus valores por defecto, si es transferencia se agrega el control de cuenta destino
     */
    private inicializaFormulario() {
        this.lineasPipe = new LineasPipe();

        // Obtener las cuentas y categorias subscribiendonos mediante el servicio
        this.state.listadoCuentasDisponibles$.subscribe( ( ctas: Cuenta[] ) => {
            this.listadoCuentas = ctas.filter(
                ( cta ) => cta.codTipoCuenta === 'CTA'
            );
            this.listadoCategorias = ctas.filter(
                ( cta ) => cta.codTipoCuenta === 'CAT'
            );

            // Ordenamiento
            this.listadoCategorias.sort( ( a, b ) => {
                const n = a.nombre
                    .toLocaleLowerCase()
                    .localeCompare( b.nombre.toLocaleLowerCase() );
                return n === 0 && a !== b
                    ? b.nombre.localeCompare( a.nombre )
                    : n;
            } );

            this.listadoCuentas.sort( ( a, b ) => {
                const n = a.nombre
                    .toLocaleLowerCase()
                    .localeCompare( b.nombre.toLocaleLowerCase() );
                return n === 0 && a !== b
                    ? b.nombre.localeCompare( a.nombre )
                    : n;
            } );
        } );

        this.formMov = this.formBuilder.group( {
            ddListadoCuentas: new UntypedFormControl(),
            ddListadoCategorias: new UntypedFormControl(),
            fechaMovCalendar: new UntypedFormControl( new Date( Date.now() ) ),
            monto: new UntypedFormControl(),
            glosa: new UntypedFormControl(),
            esIngreso: new UntypedFormControl( true ),
        } );

        if ( this.esTransferencia ) {
            this.formMov.addControl(
                'ddListadoCuentasDestino',
                new UntypedFormControl()
            );
        }
    }

    /**
     * No se muestra Agregar Movimiento
     */
    close() {
        this.seMuestraAgregarMovimiento = false;
    }

    /**
     * Este es para los nuevos solamente, y es posible recibir el id comprobante al que se va a agregar
     * @param com Comprobante opcional
     */
    public mostrarAgregarMovimiento( com: Comprobante = null ) {
        this.tituloDinamico = 'Agregar nuevo movimiento';
        this.esTransferencia = false;
        this.inicializaFormulario();
        this.seMuestraAgregarMovimiento = true;

        // Inicializamos obj Comprobante
        this.comprobante = {
            lineas: [{ esNueva: true } as LineaComprobante],
            id: com ? com.id : null,
            codComprobante: com ? com.codComprobante : null,
            esNuevo: com ? false : true,
        } as Comprobante;
    }

    /**
     * Este es para los nuevos solamente, y es posible recibir el id comprobante al que se va a agregar
     * @param com Comprobante opcional
     */
    public mostrarAgregarTransferencia( com: Comprobante = null ) {
        this.tituloDinamico = 'Agregar nueva transferencia ';

        this.esTransferencia = true;
        this.inicializaFormulario();
        this.seMuestraAgregarMovimiento = true;

        // Inicializamos estructura de Comprobante de este movimiento prefijamos el cod con la sigla TR
        this.comprobante = {
            lineas: [{ esNueva: true } as LineaComprobante],
            id: com ? com.id : null,
            codComprobante: com ? com.codComprobante : 'TR',
            esNuevo: com ? false : true,
        } as Comprobante;
    }

    /**
     * Permite Ver y Editar un movimiento partida doble
     * @param linea linea a mostrar
     */
    public mostrarPartidaDoble( linea: LineaComprobante ) {
        this.esTransferencia = false;
        this.inicializaFormulario();
        this.seMuestraAgregarMovimiento = true;

        linea.esNueva = false;

        // Inicializamos estructura de Comprobante de este movimiento
        this.comprobante = {
            lineas: [linea],
            id: linea.idComprobante,
            codComprobante: linea.codComprobante,
            esNuevo: false,
        } as Comprobante;

        // Setea los valores en el formulario
        this.formMov = this.formBuilder.group( {
            ddListadoCuentas: new UntypedFormControl(),
            ddListadoCategorias: new UntypedFormControl(),
            fechaMovCalendar: new UntypedFormControl(
                new Date( linea.fechaCreacion )
            ),
            monto: new UntypedFormControl(
                linea.monto < 0 ? linea.monto * - 1 : linea.monto
            ),
            glosa: new UntypedFormControl( linea.glosa ),
            esIngreso: new UntypedFormControl( linea.monto > 0 ? true : false ),
        } );
        this.formMov.controls.ddListadoCuentas.setValue( linea.cuenta.id );
        this.formMov.controls.ddListadoCategorias.setValue( linea.categoria.id );
        this.crearMenuContextual();

        this.tituloDinamico = `Editar movimiento #${linea.id}`;
    }

    /**
     * Rellena la estructura del comprobante y de su linea con los datos leidos desde el formulario.
     */
    leerFormularioLineaComprobante() {
        const date = this.formMov.controls.fechaMovCalendar.value;
        this.comprobante.lineas[0].fechaCreacion = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
        );
        this.comprobante.lineas[0].fechaModificacion = new Date(
            Date.now() - this.state.hoy.getTimezoneOffset() * 60000
        );
        this.comprobante.lineas[0].glosa = String(
            this.formMov.controls.glosa.value
        ).toLocaleLowerCase();
        this.comprobante.lineas[0].glosa =
            this.comprobante.lineas[0].glosa[0].toUpperCase() +
            this.comprobante.lineas[0].glosa.slice( 1 );
        this.comprobante.lineas[0].monto = this.formMov.controls.esIngreso.value
            ? this.formMov.controls.monto.value * 1
            : this.formMov.controls.monto.value * - 1;
        this.comprobante.lineas[0].cuenta = this.listadoCuentas.find(
            ( cta ) => cta.id === this.formMov.controls.ddListadoCuentas.value
        );
        this.comprobante.lineas[0].categoria = this.listadoCategorias.find(
            ( cat ) => cat.id === this.formMov.controls.ddListadoCategorias.value
        );

        this.comprobante.usuario = this.auth.user;
        this.comprobante.fechaCreacion = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
        );
    }

    /**
     * Guardar el nuevo movimiento con los datos del formulario
     */
    guardarNuevoMovimiento() {
        let msje: string;

        this.leerFormularioLineaComprobante();

        this.http
            .post( this.http.endpoint.movimientosCrear, this.comprobante )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    console.log( res );
                    // El back insertó los movimientos y retorna los ids creados
                    this.comprobante.id = res.body.data.id;
                    this.comprobante.lineas[0].id = res.body.data.idMovs[0][0];

                    if ( this.comprobante.esNuevo ) {
                        msje = `Se ha creado el nuevo comprobante ${
                            this.comprobante.id
                        } y
                            se agregó movimiento por ${this.lineasPipe.formatoMoneda(
        + this.comprobante.lineas[0].monto
    )}
                            a la cuenta "${
    this.comprobante.lineas[0].cuenta.nombre
}"
                            `;
                    } else {
                        msje = `Se ha creado un nuevo movimiento por ${this.lineasPipe.formatoMoneda(
                            + this.comprobante.lineas[0].monto
                        )} en el comprobante ${this.comprobante.id} `;
                    }

                    this.state.agregarMensaje(
                        this.state.mensajestipo.info,
                        'Agregar movimientos',
                        msje
                    );
                    this.notify.emit( { res: 'OK', data: this.comprobante } );
                    this.estaAgregando = false;
                    this.close();
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en guardarNuevoMovimiento',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Enviar a guardar la estructura del comprobante que obtiene desde el formulario.
     */
    guardarMovExistente() {
        this.leerFormularioLineaComprobante();

        this.http
            .post(
                this.http.endpoint.movimientosEditarPartidaDoble,
                this.comprobante
            )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    const msje = `Se ha actualizo el movimiento por ${this.lineasPipe.formatoMoneda(
                        this.comprobante.lineas[0].monto
                    )}
                                del comprobante ${
    this.comprobante.codComprobante
        ? this.comprobante.codComprobante
        : this.comprobante.id
}
                                a la cuenta "${
    this.comprobante.lineas[0].cuenta.nombre
}"
                            `;
                    this.state.agregarMensaje(
                        this.state.mensajestipo.info,
                        'Agregar movimientos',
                        msje
                    );

                    this.notify.emit( { res: 'OK', data: this.comprobante } );

                    this.estaAgregando = false;
                    this.close();
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en guardarMovExistente',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Enviar a guardar la estructura del comprobante que obtiene desde el formulario para una transferencia
     */
    guardarTransferencia() {
        let idCuentaDestino: number;
        this.leerFormularioLineaComprobante();

        // Cuando es transferencia va con cuenta destino
        if ( this.esTransferencia ) {
            idCuentaDestino =
                this.formMov.controls.ddListadoCuentasDestino.value;
        }

        this.http
            .post( this.http.endpoint.movimientosTransferir, {
                comprobante: this.comprobante,
                idCuentaDestino,
            } )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    // El back insertó los movimientos y retorna los ids creados
                    this.comprobante.id =
                        res.body.data.Comprobante.idComprobante;
                    this.comprobante.codComprobante =
                        res.body.data.Comprobante.codComprobante;
                    this.comprobante.lineas[1] = {
                        id: res.body.data.idMovs[1][0],
                    } as LineaComprobante;
                    // La segunda linea se crea solamente como espejo por eso solo tenemos el id
                    this.comprobante.lineas[0].id = res.body.data.idMovs[0][0];

                    const msje = `Se realizado la transferencia en el comprobante  ${
                        this.comprobante.codComprobante
                            ? this.comprobante.codComprobante
                            : this.comprobante.id
                    }`;
                    this.state.agregarMensaje(
                        this.state.mensajestipo.info,
                        'Transferencia',
                        msje
                    );

                    this.notify.emit( { res: 'OK', data: this.comprobante } );

                    this.estaAgregando = false;
                    this.close();
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en guardarTransferencia',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Evento que maneja la lógica para el submit del formulario
     */
    eventoSubmit() {
        if ( ! this.estaAgregando ) {
            if ( ! this.formMov.invalid && ! this.formMov.untouched ) {
                if ( this.esTransferencia ) {
                    this.guardarTransferencia();
                } else {
                    ! this.comprobante.lineas[0].esNueva
                        ? this.guardarMovExistente()
                        : this.guardarNuevoMovimiento();
                }
                this.estaAgregando = true;
            }
        }
    }

    /**
     * Busca en el listado de cuentas categoría el nodo que el usuario ha seleccionado
     * para rescatar su tendencia si es ingreso o egreso
     * @param idCuenta id de la cuenta seleccionada.
     */
    aplicarCategoriaIngreso( idCuenta ) {
        const buscado = this.listadoCategorias.find(
            ( cat ) => + cat.id === + idCuenta
        );
        if ( buscado ) {
            this.formMov.controls.esIngreso.setValue( buscado.esIngreso );
        }
    }

    /**
     *  Crea opciones para el menu del comprobante
     */
    crearMenuContextual() {
        // se agrega el menu desplegable
        this.menuOpcionesComprobante = [
            {
                label: 'Ver comprobante completo',
                icon: 'pi pi-book',
                command: () => {
                    this.router.navigate( ['/comprobante', this.comprobante.id] );
                },
            },
            {
                label: 'Mover a otro comprobante',
                icon: 'pi pi-clone',
                command: () => {
                    this.mostrarMoverMovimiento();
                },
            },
            {
                label: 'Eliminar movimiento',
                icon: 'pi pi-trash',
                command: () => {
                    this.btnEliminarMovimiento();
                },
            },
        ];
    }

    /**
     * Elimina el movimiento de la linea 0 del comprobante
     */
    btnEliminarMovimiento() {
        const movimientos = [];
        movimientos.push( this.comprobante.lineas[0] );
        this.confirma.confirm( {
            message:
                '¿ Eliminar el movimiento por ? (' +
                this.comprobante.lineas[0].monto +
                ')',
            acceptLabel: 'Si, Eliminar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.http
                    .post( this.http.endpoint.eliminarPartidaDoble, {
                        movimientos,
                    } )
                    .subscribe( ( res ) => {
                        if ( res.status === 200 ) {
                            this.state.agregarMensaje(
                                this.state.mensajestipo.ok,
                                'Eliminar Movimiento',
                                res.statusText
                            );
                            this.notify.emit( {
                                res: 'OK',
                                data: this.comprobante,
                            } );
                            this.close();
                        }
                    } );
            },
        } );
    }

    /**
     * Despliega el dialog para poder mover un movimiento y llama al obtener listado comprobantes.
     */
    mostrarMoverMovimiento() {
        this.moverComprobante.mostrarMoverMovimiento(
            this.comprobante.lineas[0]
        );
    }

    /**
     * Método recibe las notificaciones del componente mover movimiento y a su vez emite al ver movimientos de que un movimiento ha cambiado
     * @param res Respuesta emitida
     */
    respuestaMoverMovimiento( res: any ) {
        this.notify.emit( { res: 'OK', data: this.comprobante } );
        this.close();
    }
}
