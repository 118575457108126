<p-card
    styleClass="p-card-shadow"
    header="Grafico">
    <div style="position: relative; width: 80vw">
        <p-chart
            type="doughnut"
            [data]="dataPie"
            [options]="chartOptions"></p-chart>
    </div>
</p-card>
