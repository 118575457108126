<p-dialog [(visible)]="estaVisible">
    <ng-template pTemplate="header"> Eliminar movimiento </ng-template>
    <p-table
        [value]="listaMovimientosEliminados"
        responsiveLayout="scroll"
        *ngIf="listaMovimientosEliminados">
        <ng-template pTemplate="caption">
            Se eliminarán los siguientes movimientos definitivamente del
            sistema, favor revisar:
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Fecha</th>
                <th>Comprobante</th>
                <th>Cuenta</th>
                <th>Categoría</th>
                <th>Glosa</th>
                <th>Monto</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-mov>
            <tr>
                <td>{{ mov.fechaCreacionCorta }}</td>
                <td>
                    <ng-container
                        *ngIf="!mov.codComprobante; else elseComprobante">
                        {{ mov.idComprobante }}
                    </ng-container>
                    <ng-template #elseComprobante>
                        {{ mov.codComprobante }}
                    </ng-template>
                </td>
                <td>{{ mov.cuenta.nombre }}</td>
                <td>{{ mov.categoria.nombre }}</td>
                <td>{{ mov.glosa }}</td>
                <td class="text-right">
                    {{ mov.monto | lineas : 'formatoMoneda' }}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="grid">
                <div class="col text-left">
                    <p-button
                        (click)="close()"
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        styleClass="p-button-text"></p-button>
                </div>
                <div class="col text-right">
                    <p-button
                        (click)="eliminar()"
                        type="button"
                        label="Eliminar"
                        icon="pi pi-trash"
                        styleClass="p-button-text"></p-button>
                </div>
            </div>
        </ng-template>
    </p-table>
</p-dialog>
