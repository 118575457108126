<form [formGroup]="formCuenta">
    <p-dialog
        [(visible)]="displayModal"
        [modal]="true"
        [style]="{ width: '80vw', height: '40vh' }"
        [draggable]="false"
        [resizable]="false">
        <ng-template pTemplate="header">
            <div
                class="grid justify-content-start"
                style="width: 100%">
                <div class="col-12">
                    {{ tituloMostrar }}
                </div>
            </div>
        </ng-template>

        <div class="grid">
            <div class="field col-12 md:col-12">
                <span class="p-float-label"
                    >Color
                    <p-colorPicker
                        formControlName="color"
                       ></p-colorPicker>
                </span>
            </div>
        </div>

        <div class="grid">
            <div class="field col-8 md:col-8">
                <span class="p-float-label">
                    <p-dropdown
                        formControlName="ddtipocuenta"
                        name="ddtipocuenta"
                        [options]="tiposdecuenta"
                        placeholder="Seleccione un tipo de cuenta"
                        [style]="{ width: '70%' }"
                        required
                        optionLabel="nombre"
                        optionValue="codTipoCuenta"
                        appendTo="body">
                    </p-dropdown>
                </span>
            </div>
            <div class="field col-4 md:col-4 text-right">
                <p-inputSwitch
                    formControlName="esIngreso"
                    name="esIngreso"></p-inputSwitch>
                <label
                    for="esIngreso"
                    class="col-fixed">
                    <span *ngIf="formCuenta.controls.esIngreso.value"
                        >Ingreso</span
                    >
                    <span *ngIf="!formCuenta.controls.esIngreso.value"
                        >Egreso</span
                    >
                </label>
            </div>
        </div>

        <div class="grid">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <input
                        id="txtdescripcion"
                        name="txtdescripcion"
                        formControlName="txtdescripcion"
                        style="width: 100%"
                        type="text"
                        pInputText
                        required
                        class="p-inputtext-sm" />
                    <label for="txtdescripcion"
                        >Descripci&oacute;n Cuenta / Categoría</label
                    >
                </span>
            </div>
        </div>

        <p-footer style="width: 100%">
            <div class="grid">
                <div class="col text-left">
                    <p-button
                        (click)="close()"
                        icon="pi pi-times"
                        type="button"
                        label="Cancelar"
                        styleClass="p-button-text"></p-button>
                </div>
                <div class="col text-right">
                    <p-button
                        *ngIf="this.tituloMostrar === 'Agregar'"
                        [disabled]="formCuenta.invalid || formCuenta.pristine"
                        type="submit"
                        label="Guardar"
                        icon="pi pi-check"
                        styleClass="p-button-text"
                        (onClick)="guardarNuevaCuenta()"></p-button>
                    <p-button
                        *ngIf="this.tituloMostrar === 'Editar'"
                        [disabled]="formCuenta.invalid || formCuenta.pristine"
                        type="submit"
                        label="Actualizar"
                        icon="pi pi-check"
                        styleClass="p-button-text"
                        (onClick)="actualizarCuenta()"></p-button>
                </div>
            </div>
        </p-footer>
    </p-dialog>
</form>
