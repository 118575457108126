import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../servicios/authentication.service';

/**
 * Guard protege las vistas del sistema
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    /**
     * Constructor del componente
     * @param router Servicio de gestión de rutas
     * @param appAuth ervicios de autenticación
     */
    constructor(
        private router: Router,
        private appAuth: AuthenticationService
    ) {}

    /**
     * valida si el usuario actual puede acceder a la vista
     * @returns veradero o Falso si se obtiene un token válido.
     */
    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.appAuth.obtenerToken()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
