import { Component, OnInit, ViewChild, ViewEncapsulation, effect } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { LineaComprobante } from 'src/app/interfaces/linea-comprobante';
import { HttpService } from 'src/app/servicios/http.service';
import { StateService } from 'src/app/servicios/state.service';
import { Comprobante } from 'src/app/interfaces/comprobante';
import { AgregarMovimientosComponent } from '../../movimientos/agregar-movimientos/agregar-movimientos.component';
import { Cuenta } from 'src/app/interfaces/cuenta';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/servicios/authentication.service';
import { ConfirmaEliminarMovimientosComponent } from 'src/app/dialog/confirma-eliminar-movimientos/confirma-eliminar-movimientos.component';
import { AppConfigService } from 'src/app/servicios/app-config.service';

/**
 * Permite editar un comprobante en vista contable de partida doble
 */
@Component( {
    selector: 'app-crear-comprobante',
    templateUrl: './crear-comprobante.component.html',
    styleUrls: ['./crear-comprobante.component.less'],
    encapsulation: ViewEncapsulation.None,
} )
export class CrearComprobanteComponent implements OnInit {
    /**
     * Componente que permite agregar nuevo movimiento
     */
    @ViewChild( AgregarMovimientosComponent )
        agregarMovComponent: AgregarMovimientosComponent;
    /**
     * Componente que controla las confirmaciones
     */
    @ViewChild( ConfirmaEliminarMovimientosComponent )
        eliminarComponent: ConfirmaEliminarMovimientosComponent;
    /**
     * LIneas de respaldo para la detección de las modificaciones
     */
    lineasRespaldo: LineaComprobante[];
    /**
     * Listado de cuentas del sistema
     */
    cuentas: Cuenta[];
    /**
     * Listado de ctageorías del sistema
     */
    categorias: Cuenta[];
    /**
     * Listad de cuentas y/o categorías
     */
    cuentaOcategoria: Cuenta[];
    /**
     * Inidcador que determina si se habilita el botón de grabar comprobante
     */
    puedeGrabarComprobante = false;
    /**
     * Comprobante al que pertenecen las líneas de movimientos
     */
    comprobante: Comprobante;
    /**
     * Variable para el cambio o asignación de tag a un comprobante
     */
    codComprobanteEditado: string;
    /**
     * Almacena los movimientos seleccionados por lotes
     */
    movimientosLotes: LineaComprobante[];
    /**
     * Almacena las lineas que se van a grabar o actualizar
     */
    lineasListas: LineaComprobante[];
    /**
     * Monto de la suma debe
     */
    sumaDebe: number;
    /**
     * Monto de la suma haber
     */
    sumaHaber: number;
    /**
     * suma de los montos
     */
    sumaMonto: number;
    /**
     * Variable que indica el número de página en la paginación
     */
    first: number;
    /**
     * Indica la cantidad de líneas a mostrar
     */
    rows = 20;
    /**
     * variable que identifica el indice del panel que se encuentra activo
     */
    panelActivo = 0;
    /**
     * Tamaño de la tabla para la visualización
     */
    sizeTable: string;

    /**
     * Función para la paginación para mostrar mensaje de "Mostrando desde..."
     */
    next() {
        this.first = this.first + this.rows;
    }
    /**
     * Función para la paginación de la página previa
     */
    prev() {
        this.first = this.first - this.rows;
    }
    /**
     * Función que resetea la primera página de la paginación
     */
    reset() {
        this.first = 0;
    }
    /**
     * Función que indica si está en la última página la paginación
     * @returns Verdadero o falso si es que es el caso
     */
    isLastPage(): boolean {
        return this.comprobante.lineas
            ? this.first === this.comprobante.lineas.length - this.rows
            : true;
    }
    /**
     * * Función que indica si está en la primera página la paginación
     * @returns Verdadero o falso si es que es el caso
     */
    isFirstPage(): boolean {
        return this.comprobante.lineas ? this.first === 0 : true;
    }

    /**
     * Constructor de la clase con la injección de dependencias
     * @param http  Servicio para el manejo de las peticiones http
     * @param state Servicio para el manejo de los estados y maestros
     * @param confirma Servicio para el manejo de la confirmación de eliminar
     * @param route Para el manejo del redireccionamiento
     * @param router Servicio para el redireccionamiento
     * @param auth Servicio de autenticación
     */
    constructor(
        private http: HttpService,
        private state: StateService,
        private confirma: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthenticationService,
        public appConfig: AppConfigService
    ) {
        this.state.configurarModulo(
            'Comprobante completo',
            'Muestra comprobante completo con todos los movimientos que incluye.',
            true
        );

        this.sumaMonto = 0;
        this.sumaDebe = 0;
        this.sumaHaber = 0;

        this.sizeTable = this.appConfig.sizeClass();
    }

    /**
     * OnInit del componente
     */
    ngOnInit(): void {
        this.comprobante = {} as Comprobante;

        this.comprobante.id = this.route.snapshot.params.id;

        // Obtener las cuentas y categorias
        this.state.listadoCuentasDisponibles$.subscribe( ( ctas: Cuenta[] ) => {
            this.cuentas = ctas.filter( ( cta ) => cta.codTipoCuenta === 'CTA' );
            this.categorias = ctas.filter( ( cta ) => cta.codTipoCuenta === 'CAT' );

            this.cambiarVista( this.panelActivo );
            this.movimientosLotes = [];
            this.lineasListas = [];
            this.puedeGrabarComprobante = false;
        } );
    }

    /**
     * Tabla de movimientos consolidados
     * @param respuesta Respuesta previa en caso de que exista una solicitud previa
     */
    private inicializaVistaSimple( respuesta?: { res?: string; data: any } ) {
        const objConsulta = {
            usuario: this.auth.user.codUsuario,
            comprobante: this.comprobante.id,
            orden: 'fecha',
            fechaInicio: new Date( this.state.primerAgno, 0, 1 ),
            fechaFin: this.state.finAño,
            cuenta: 0,
        };
        // Solicitar los datos
        this.http
            .post( this.http.endpoint.movimientos, objConsulta )
            .subscribe( ( res ) => {
                if ( res.status === 200 && res.body != null ) {
                    if ( ! res.body.comprobante ) {
                        this.state.agregarMensaje(
                            this.state.mensajestipo.error,
                            ' Comprobante no encontrado',
                            `No existe el comprobante  ${this.comprobante.id} `
                        );
                    } else {
                        const listaMovimientos = [];

                        this.comprobante.id = res.body.comprobante.id;
                        this.comprobante.fechaCreacion =
                            res.body.comprobante.fechaCreacion;
                        this.comprobante.codComprobante =
                            res.body.comprobante.codComprobante;

                        res.body.datos.forEach( ( lin ) => {
                            const nuevaLinea = {} as LineaComprobante;
                            nuevaLinea.id = lin.idMov;
                            nuevaLinea.idComprobante = lin.idComprobante;
                            nuevaLinea.codComprobante = lin.codComprobante;
                            nuevaLinea.fechaCreacion = lin.fechaCreacion;
                            nuevaLinea.fechaCreacionCorta = lin.fechaCreacion
                                .toString()
                                .substring( 0, 10 );
                            nuevaLinea.fechaModificacion = new Date(
                                this.state.hoy.getTime() -
                                    this.state.hoy.getTimezoneOffset() * 60000
                            );
                            nuevaLinea.cuenta = this.cuentas.find(
                                ( cta ) => cta.id === lin.idCta
                            );
                            nuevaLinea.categoria = this.categorias.find(
                                ( cat ) => cat.id === lin.idCat
                            );
                            nuevaLinea.idMovRef = lin.idRef;
                            nuevaLinea.monto = + lin.monto;
                            nuevaLinea.glosa = lin.movGlosa;

                            // Si existe una respuesta previa se busca la línea para ser marcada
                            if ( respuesta ) {
                                const lineas: LineaComprobante[] =
                                    respuesta.data.lineas;
                                const linearespuesta = lineas.find(
                                    ( lr ) => + lr.id === + nuevaLinea.id
                                );
                                if ( linearespuesta ) {
                                    nuevaLinea.esNueva = true;
                                    setInterval( () => {
                                        nuevaLinea.esNueva = false;
                                    }, 9000 );
                                }
                            }

                            listaMovimientos.push( nuevaLinea );
                        } );

                        this.comprobante.lineas = listaMovimientos;
                    }
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        ' Error en inicializaVistaSimple',
                        res.status + ' : ' + res.statusText
                    );
                }
            } );
    }

    /**
     * Obtiene las cuentas y categorias y genera el llamado para armar la lista contable editable
     * @param obj Objeto de respuesta previa en caso que exista
     */
    inicializaVistaContable( obj?: any ) {
        // Una vez cargadas las cuentas y categorias consulta si es nuevo comprobante o es nuevo
        if ( ! this.comprobante.id ) {
            this.comprobante.codComprobante = '';
            this.comprobante.esNuevo = true;
            this.comprobante.lineas = [];
        } else {
            const objEnviar = {
                comprobante: this.comprobante.id,
                usuario: this.auth.user.codUsuario,
            };
            this.http
                .post( this.http.endpoint.movimientosComprobante, objEnviar )
                .subscribe( ( res ) => {
                    if ( res.status === 200 && res.body != null ) {
                        if ( res.body.comprobante ) {
                            this.armarComprobanteCompleto( res.body, obj );
                        } else {
                            this.state.agregarMensaje(
                                this.state.mensajestipo.advertencia,
                                'Comprobante no encontrado',
                                `No existe el comprobante ${this.comprobante.id}`
                            );
                        }
                    }
                } );
        }
    }
    /**
     * Transforma el array 'datos' en obj LineasComprobante y se agregan a las líneas del comprobante
     * @param datos datos que vienen desd el back con cada movimiento
     * @param respuesta Respuesta previa si existe
     */
    armarComprobanteCompleto( datos: any, respuesta?: any ) {
        this.lineasRespaldo = [];
        this.comprobante = {} as Comprobante;
        this.comprobante.esNuevo = false;
        this.comprobante.lineas = [] as LineaComprobante[];
        this.comprobante.id = datos.comprobante.id;
        this.comprobante.codComprobante = datos.comprobante.codComprobante;
        this.comprobante.fechaCreacion = datos.comprobante.fechaCreacion;
        this.movimientosLotes = [];

        datos.lineas.forEach( ( lin: any ) => {
            const nuevaLinea = {} as LineaComprobante;
            nuevaLinea.id = lin.id;
            nuevaLinea.orden = + lin.orden;
            nuevaLinea.idComprobante = this.comprobante.id;
            nuevaLinea.codComprobante = this.comprobante.codComprobante;
            nuevaLinea.fechaCreacion = lin.fechaCreacion;
            nuevaLinea.fechaCreacionCorta = lin.fechaCreacion
                .toString()
                .substring( 0, 10 );
            nuevaLinea.fechaModificacion = new Date(
                this.state.hoy.getTime() -
                    this.state.hoy.getTimezoneOffset() * 60000
            );
            nuevaLinea.idMovRef = lin.idMovRef;
            if ( nuevaLinea.idMovRef === null ) {
                nuevaLinea.cuenta = this.cuentas.find(
                    ( cta ) => cta.id === lin.idCuenta
                );
            } else {
                nuevaLinea.cuenta = this.categorias.find(
                    ( cat ) => cat.id === lin.idCuenta
                );
            }

            nuevaLinea.monto = + lin.monto;
            nuevaLinea.montoDebe = + lin.montoDebe;
            nuevaLinea.montoHaber = + lin.montoHaber;
            nuevaLinea.glosa = lin.glosa;
            nuevaLinea.estaEditada = false;
            nuevaLinea.esNueva = false;
            nuevaLinea.estaListaParaGrabar = false;

            // Si existe una respuesta previa se busca la línea para ser marcada
            if ( respuesta ) {
                const lineas: LineaComprobante[] = respuesta.data.lineas;
                const linearespuesta = lineas.find(
                    ( lr ) => + lr.id === + nuevaLinea.id
                );
                if ( linearespuesta ) {
                    nuevaLinea.esNueva = true;
                    setInterval( () => {
                        nuevaLinea.esNueva = false;
                    }, 9000 );
                }
            }

            this.comprobante.lineas.push( nuevaLinea );
            // Creamos una copia del listado de lineas
            this.lineasRespaldo.push( JSON.parse( JSON.stringify( nuevaLinea ) ) );
        } );

        // Está nuevo, nada que grabar
        this.puedeGrabarComprobante = false;
        this.totalizarSeleccionados();
    }
    /**
     * Muestra ventana de crear movimiento,
     * se pasa el comprobante al que se asignará el movimiento creado
     */
    btnAgregarMovimiento() {
        this.agregarMovComponent.mostrarAgregarMovimiento( this.comprobante );
    }

    /**
     * Muestra ventana de crear movimiento tipo transferencia,
     * se pasa el comprobante al que se asignará el movimiento creado
     */
    btnAgregarTransferencia() {
        this.agregarMovComponent.mostrarAgregarTransferencia( this.comprobante );
    }

    /**
     * Evento notificado cada vez que se cierra el dialog de agregar movimiento
     * Si es ok se actualiza las líneas para ser mostradas.
     * @param obj Resultado transmitido a la función
     */
    cierraAgregarMovimiento( obj: { res: string; data: Comprobante } ) {
        if ( obj.res === 'OK' ) {
            // Actualizamos las lineas
            this.cambiarVista( this.panelActivo, obj );
        }
    }

    /**
     * Al seleccionar una cuenta del listado de cuentas, se setea el label a mostrar
     * @param linea     Línea del comprobante donde se está seteando la cuenta
     * @param ddcuenta  Control del listado de cuentas.
     */
    setearCuentaLinea( linea: LineaComprobante, ddcuenta: Dropdown ) {
        linea.cuenta = this.cuentas.find(
            ( cta ) => cta.id === ddcuenta.selectedOption.value
        );
    }

    /**
     * Acción del botón eliminar movimientos que detecta si está en modo panel consolidado o de contabilidad antes de enviar a eliminar
     */
    btnEliminar() {
        if ( this.panelActivo === 0 ) {
            this.eliminarComponent.mostrarEliminarListadoMovimientos(
                this.movimientosLotes
            );
        } else {
            // Sólo se considera los mov padres
            const movimientosPadre = this.movimientosLotes.filter(
                ( x ) => x.idMovRef === null
            );

            // Se buscan los movimientos hijos
            movimientosPadre.forEach( ( mov ) => {
                const linea: LineaComprobante = this.comprobante.lineas.find(
                    ( ml ) => ml.idMovRef === mov.id
                );

                mov.categoria = linea.cuenta;
            } );

            this.eliminarComponent.mostrarEliminarListadoMovimientos(
                movimientosPadre
            );
        }
    }

    /**
     * Controla el evento emitido cuando se llama a eliminar movimiento.
     * @param emitido Objeto que contiene la respuesta desde el Eliminar movimiento
     */
    respuestaEliminarMovimientos( emitido: any[] ) {
        const res = emitido[0];

        this.state.agregarMensaje(
            this.state.mensajestipo.ok,
            'Eliminar Movimiento',
            res.statusText
        );
        this.cambiarVista( this.panelActivo );
    }

    /**
     * Evento del botón del toolbar para guardar los cambios de edición
     */
    guardarCambios() {
        const idComprobante = this.comprobante.id;
        this.verificarSiSePuedeGrabar();

        const datosaGuardar = {
            idComprobante,
            lineasEditadas: this.lineasListas,
            codUsuario: this.auth.user.codUsuario,
        };

        this.http
            .post( this.http.endpoint.movimientosEditar, datosaGuardar )
            .subscribe( ( res ) => {
                if ( res.status === 201 ) {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.ok,
                        `Comprobante ${idComprobante}`,
                        res.statusText
                    );
                    this.cambiarVista( this.panelActivo );
                }
            } );
    }

    /**
     * Elimina todas las líneas de un comprobante
     */
    btnEliminarComprobante() {
        this.confirma.confirm( {
            message: `¿ Eliminar el comprobante  ${this.comprobante.id}  y todos sus movimientos ?`,
            acceptLabel: 'Si, Eliminar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.http
                    .post( this.http.endpoint.comprobanteEliminar, {
                        comprobante: this.comprobante.id,
                    } )
                    .subscribe( ( res ) => {
                        if ( res.status === 200 ) {
                            this.state.agregarMensaje(
                                this.state.mensajestipo.ok,
                                'Comprobante eliminado',
                                res.statusText
                            );
                            this.router.navigate( ['../../movimientos'] );
                        }
                    } );
            },
        } );
    }

    /**
     * Evento para operaciones al momento de editar una celda
     * @param event Trae el campo editado, y la línea de comprobante al que corresponde.
     */
    onEditInit( event: { field: string; data: LineaComprobante } ): void {
        const linea: LineaComprobante = event.data;

        // Si no tiene idMovref es cuenta sino es categoría
        // esto determina que cuentas se muestran elegibles para el usuario
        if ( ! linea.idMovRef ) {
            this.cuentaOcategoria = this.cuentas;
        } else {
            this.cuentaOcategoria = this.categorias;
        }
    }

    /**
     * Se completa el editar un campo se evalua si la línea está lista para ser grabada.
     * @param event Evento con el field y la data
     */
    onEditComplete( event: { field: string; data: LineaComprobante } ): void {
        const linea: LineaComprobante = event.data;
        linea.estaListaParaGrabar = false;
        linea.estaEditada = false;
        const respaldo = this.lineasRespaldo.find( ( lin ) => linea.id === lin.id );

        // Verificamos que tenga una cuenta, que tenga una glosa y que tenga un monto
        if (
            linea.cuenta != null &&
            linea.glosa.length > 0 &&
            linea.monto !== 0 &&
            linea.monto.toString().trim() !== '-' &&
            linea.monto.toString().trim() !== ''
        ) {
            // Verificamos si alguno cambió
            if ( linea.cuenta.id !== respaldo.cuenta.id ) {
                linea.estaListaParaGrabar = true;
                linea.estaEditada = true;
            }

            if ( linea.glosa !== respaldo.glosa ) {
                linea.estaListaParaGrabar = true;
                linea.estaEditada = true;
            }

            if ( linea.monto !== respaldo.monto ) {
                // Si edita el monto, calculamos nuevamente el debe y haber
                this.calcularDebeHaber( linea );

                // Toca actualizar su mov relacionado
                let lineaREF = null;
                if ( linea.idMovRef ) {
                    lineaREF = this.comprobante.lineas.find(
                        ( x ) => x.id === linea.idMovRef
                    );
                } else {
                    lineaREF = this.comprobante.lineas.find(
                        ( x ) => x.idMovRef === linea.id
                    );
                }

                if ( lineaREF ) {
                    // El monto se multiplica * -1 ya que es el opuesto
                    lineaREF.monto = linea.monto * - 1;

                    // Calculamos el debe y haber del mov relacionado y marcamos la linea para que se graben
                    this.calcularDebeHaber( lineaREF );
                    lineaREF.listaParaGrabar = true;
                    lineaREF.esEditada = true;
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        'Error de partida doble',
                        'No se encontró la línea relacionada para la partida doble, favor verificar'
                    );
                }
            }
            linea.estaEditada = true;
            linea.estaListaParaGrabar = true;
            // Verificamos si se puede grabar
            this.verificarSiSePuedeGrabar();
        } else {
            linea.estaEditada = false;
            linea.estaListaParaGrabar = false;
        }
    }

    /**
     * Si el monto ya está correctamente asignado, esta función determina si va e el debe o haber para mostrarse
     * @param linea a calcular
     */
    calcularDebeHaber( linea: LineaComprobante ) {
        if ( linea.monto >= 0 ) {
            linea.montoDebe = linea.monto;
            linea.montoHaber = 0;
        } else {
            linea.montoHaber = linea.monto * - 1;
            linea.montoDebe = 0;
        }
    }

    /**
     * Verifica y almacena las líneas que estén listas para grabar y en base a esto determina si se puede grabar
     */
    verificarSiSePuedeGrabar() {
        // Creamos array con las líneas listas para grabar
        this.lineasListas = this.comprobante.lineas.reduce(
            ( a, o ) => ( o.estaListaParaGrabar && a.push( o ), a ),
            []
        );

        this.puedeGrabarComprobante =
            this.lineasListas.length > 0 ? true : false;
    }

    /**
     * Restaura los valores a editar
     * @param event Evento con el field y la data
     */
    onEditCancel( event: { field: string; data: LineaComprobante } ): void {
        const linea: LineaComprobante = event.data;
        const respaldo = this.lineasRespaldo.find( ( lin ) => linea.id === lin.id );

        if ( event.field === 'monto' ) {
            linea.monto = respaldo.monto;
        } else if ( event.field === 'cuenta' ) {
            linea.cuenta = respaldo.cuenta;
        } else if ( event.field === 'glosa' ) {
            linea.glosa = respaldo.glosa;
        }

        this.verificarSiSePuedeGrabar();
    }

    /**
     * Se activa cuando se muestra el input de editar el tag del comprobante
     * backup del valor en este momento para detectar si es distinto al guardaTagComprobante()
     */
    cambiarTagComprobante() {
        this.codComprobanteEditado = this.comprobante.codComprobante;
    }

    /**
     * Guarda la información del tag del comprobante
     */
    guardarTagComprobante() {
        if ( this.comprobante.codComprobante !== this.codComprobanteEditado ) {
            this.http
                .post(
                    this.http.endpoint.comprobanteAgregarTag,
                    this.comprobante
                )
                .subscribe( ( res ) => {
                    if ( res.status === 201 ) {
                        this.state.agregarMensaje(
                            this.state.mensajestipo.info,
                            'Tag de comprobante actualizado',
                            res.statusText
                        );

                        // A todas las lineas del comprobante se le agrega el nuevo código.
                        this.comprobante.lineas.forEach(
                            ( lins ) =>
                                ( lins.codComprobante =
                                    this.comprobante.codComprobante )
                        );
                    }
                } );
        }
    }

    /**
     * Evento al seleccionar un movimiento
     * @param event Objeto evento que incluye el nodo en el data
     */
    eventoSeleccionDeMovimiento( event ) {
        this.totalizarSeleccionados();
    }

    /**
     * Realiza la suma de los montos de los movimientos seleccionados
     */
    totalizarSeleccionados() {
        this.sumaMonto = 0;
        this.sumaDebe = 0;
        this.sumaHaber = 0;

        this.movimientosLotes.forEach( ( mov ) => {
            this.sumaMonto += + mov.monto;
            this.sumaHaber += + mov.montoHaber;
            this.sumaDebe += + mov.montoDebe;
        } );
    }

    /**
     * Muestra el componente que permite ver y editar un movimiento.
     * @param mov Movimiento a procesar
     */
    mostrarMovimiento( mov: LineaComprobante ) {
        this.agregarMovComponent.mostrarPartidaDoble( mov );
    }

    /**
     * Maneja el cambio de vista consolidad y contable
     * @param indice Panel activo
     * @param obj Respuesta en caso de que exista una solicitud previa
     */
    cambiarVista( indice: number, obj?: { res: string; data: Comprobante } ) {
        this.panelActivo = indice;

        // Limpio las lineas
        this.comprobante.lineas = [] as LineaComprobante[];

        if ( this.panelActivo === 0 ) {
            this.inicializaVistaSimple( obj );
        } else {
            this.inicializaVistaContable( obj );
        }
    }


    /**
     * El efecto se dispara cada vez que una señal cambia
    */
    public sizetableChangedEffect = effect( () => {

        this.sizeTable = this.appConfig.sizeClass();
    } );
}
