import { Component, OnInit, ViewChild } from '@angular/core';
import { CrearCuentasModalComponent } from 'src/app/cuentas/crear-cuentas-modal/crear-cuentas-modal.component';
import { HttpService } from '../../servicios/http.service';
import { StateService } from '../../servicios/state.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Cuenta } from 'src/app/interfaces/cuenta';

/**
 * Componente que muestra una cuenta del sistema
 */
@Component( {
    selector: 'app-ver-cuentas',
    templateUrl: './ver-cuentas.component.html',
    styleUrls: ['./ver-cuentas.component.less'],
} )
export class VerCuentasComponent implements OnInit {
    /**
     * Contiene el listado de cuentas
     */
    listado: Cuenta[] = [];
    /**
     * Contiene la cuenta seleccionada
     */
    cuentaSelecionada: Cuenta;
    /**
     * Menú para las filas de la tabla de cuentas
     */
    itemsMenuFila: MenuItem[] = [];
    /**
     * Componente para crear una nueva cuenta
     */
    @ViewChild( CrearCuentasModalComponent )
        crearCuentaComponent: CrearCuentasModalComponent;

    /**
     * Constructor del componente
     * @param http Servicio para el manejo de las peticiones http
     * @param state Servicio para el manejo de los estados
     * @param confirmationService Servicio para el control de las confirmaciones
     */
    constructor(
        private http: HttpService,
        private state: StateService,
        private confirmationService: ConfirmationService
    ) {
        this.state.configurarModulo(
            'Cuentas del sistema',
            'Permite administrar las cuentas y categorías del sistema.',
            true
        );
    }

    /**
     * OnInit del componente
     */
    ngOnInit(): void {
        this.state.listadoCuentasDisponibles$.subscribe( ( ctas: Cuenta[] ) => {
            this.listado = ctas;
            this.crearMenuContextual();
            this.listado.sort( ( a, b ) => a.id - b.id );
        } );
    }

    /**
     * Despliega el popup del boton para crear una nueva cuenta
     */
    crearCuenta() {
        this.crearCuentaComponent.mostrarAgregarNuevaCuentaCategoria();
    }

    /**
     * Muestra la ventana de edición de la cuenta
     */
    editaCuentaCategoria( cta: Cuenta ) {
        this.crearCuentaComponent.mostrarEditarCuentaCategoria( cta );
    }

    /**
     * Recibe acción del popup de crear-editar cuenta/categoría
     * @param action acción a realizar
     */
    actualizaCuentas( action ) {
        if ( action === 'ok' ) {
            this.state.obtenerListadoCuentas();
        }
    }

    /**
     * Elimina una cuenta
     */
    eliminarCuenta() {
        this.confirmationService.confirm( {
            message:
                '¿ Desea eliminar la cuenta ?' + this.cuentaSelecionada.nombre,
            accept: () => {
                this.http
                    .post( this.http.endpoint.cuentasEliminar, {
                        id: this.cuentaSelecionada.id,
                    } )
                    .subscribe( ( res ) => {
                        if ( res.status === 200 ) {
                            this.state.agregarMensaje(
                                this.state.mensajestipo.ok,
                                'Eliminar Cuenta',
                                'La cuenta se ha eliminado correctamente' +
                                    res.statusText
                            );
                            this.actualizaCuentas( 'ok' );
                        } else {
                            this.state.agregarMensaje(
                                this.state.mensajestipo.error,
                                'Error en eliminarCuenta',
                                `${res.status}  :   ${res.statusText}`
                            );
                        }
                    } );
            },
        } );
    }

    /**
     *  Crea un MenuItem para las opciones de cada línea
     */
    crearMenuContextual() {
        // Se agrega el menu desplegable
        this.itemsMenuFila = [
            {
                label: 'Eliminar cuenta',
                icon: 'pi pi-trash',
                command: () => {
                    this.eliminarCuenta();
                },
            },
        ];
    }
}
