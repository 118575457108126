import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../servicios/error.service';

@Component({
    selector: 'app-pagina-error',
    templateUrl: './pagina-error.component.html',
    styleUrls: ['./pagina-error.component.less'],
})
export class PaginaErrorComponent implements OnInit {
    mensaje: string;
    codError: string;

    constructor(private errorService: ErrorService, private router: Router) {}

    ngOnInit(): void {
        if (this.errorService.errorSistema != null) {
            this.mensaje =
                this.errorService.errorSistema.ok +
                ' ' +
                this.errorService.errorSistema.message;
            this.codError = this.errorService.errorSistema.status;
        } else {
            this.router.navigate(['/']);
        }
    }
}
