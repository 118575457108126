import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../interfaces/usuario';
import * as CryptoJS from 'crypto-js';
import { AppConfigService } from './app-config.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Servicio encargado de autenticar a un usuario e informar del usuario actual a los demás componentes
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    /**
     * Usuario actual
     */
    user: Usuario;
    /**
     * Obejto privado para el manejo del usuario actual
     */
    private currentUserSubject: BehaviorSubject<Usuario>;
    /**
     * Observable encargado de almacenar el usuario actual
     */
    public currentUser: Observable<Usuario>;

    /**
     * Constructor del servicio que inyecta las dependencias
     * @param appConfig Configuración de la aplicación
     * @param http Servicio de manejo de peticiones http
     * @param errorService Servicio de manejo de errores
     * @param router Rutas del sistema
     */
    constructor(
        private appConfig: AppConfigService,
        private http: HttpClient,
        private errorService: ErrorService,
        private router: Router
    ) {
        const usuario: any = null;
        this.currentUserSubject = new BehaviorSubject<Usuario>(
            JSON.parse(localStorage.getItem('usuario') || usuario)
        );
        this.currentUser = this.currentUserSubject.asObservable();
        this.user = JSON.parse(localStorage.getItem('usuario') || usuario);
    }

    /**
     * Realiza la llamada de autentificación a la API directamente
     * y deriva la respuesta al RespuestaProcesalogin
     * @param usuario  Usuario a autentificar
     * @param contrasena Clave ingresada
     */
    login(usuario: string, contrasena: string): HttpResponse<any> | any {
        const hash = CryptoJS.MD5(contrasena).toString();
        const apiUrl = this.appConfig.getConfig('apiUrl');
        const headers = new HttpHeaders();

        const objLogin = {
            usuario,
            contrasena: hash,
        };

        return this.http
            .post<any>(apiUrl + '/login', objLogin, {
                headers,
                observe: 'response',
            })
            .pipe(
                map((res) => {
                    this.RespuestaProcesaLogin(res);
                    return res;
                }),
                catchError(this.errorService.handleError<any[]>(apiUrl, []))
            );
    }

    /**
     * Respuesta del Login para logear un usuario, si es codigo autorizado establece el usuario actual en el localstorage
     * @param res respuesta de la API
     */
    private RespuestaProcesaLogin(res: any) {
        // Está autorizado con código 201
        if (res && res.status === 201) {
            // Rescatar el usuario localmente y en el localstorage
            this.user = res.body.usuario;
            localStorage.setItem('usuario', JSON.stringify(res.body.usuario));
            this.currentUserSubject.next(res.body.usuario);
        } else {
            this.router.navigate(['/login']);
        }
    }

    /**
     * Para el guard de las vistas y para el HTTP Interceptor es consultado este método
     * y según esto se determina si el usuario puede acceder o puede hacer la petición http.
     * Todo QUIZAS DEBERIA RESCATARLO DEL LOCALSTORAGE ???
     * @returns El token del user.
     */
    obtenerToken() {
        return this.user ? this.user.token : false;
    }

    /**
     * Salir del sistema y redirigir al login
     */
    logout() {
        this.user = null;
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
