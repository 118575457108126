import { Component, OnInit } from '@angular/core';
import { StateService } from '../servicios/state.service';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../servicios/authentication.service';

@Component( {
    selector: 'app-pagina-login',
    templateUrl: './pagina-login.component.html',
    styleUrls: ['./pagina-login.component.less'],
} )
export class PaginaLoginComponent implements OnInit {
    public formulario: UntypedFormGroup;

    constructor(
        private state: StateService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private authService: AuthenticationService
    ) {
        this.formulario = this.fb.group( {
            username: new UntypedFormControl( '' ),
            password: new UntypedFormControl( '' ),
        } );
        this.state.configurarModulo( '', '', false );
    }

    ngOnInit(): void {}

    /**
     * Enviar el formulario con el usuario y contraseña al servicio auth para validar su acceso
     */
    onSubmit() {
        this.authService
            .login(
                this.formulario.controls.username.value,
                this.formulario.controls.password.value
            )
            .subscribe( ( res: { status: number; statusText: string } ) => {
                if ( res.status === 201 ) {
                    return this.router.navigate( ['/tablero'] );
                } else if ( res.status === 401 ) {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        'Acceso denegado',
                        res.statusText
                    );
                } else {
                    this.state.agregarMensaje(
                        this.state.mensajestipo.error,
                        'Acceso denegado',
                        ' Ha ocurrido un problema.'
                    );
                }
            } );
    }
}
